<template>
  <li
    :tabindex="item.disabled || isHeading ? -1 : 0"
    class="flex min-h-10 select-none flex-row items-center justify-between gap-1 whitespace-normal px-3 py-2 outline-[1.5px] outline-success-100 focus-visible:outline"
    :name="item.value"
    :class="[
      {
        'text-black-60': item.disabled,
        'border-t border-solid border-black-10 first:border-none': isHeading && divider,
        'border-b border-solid border-black-10': item.divider,
        '!min-h-10 cursor-default': isHeading,
        'cursor-pointer bg-primary-05 text-primary-120': shallowValue === item.value,
        'cursor-pointer hover:bg-black-03 active:bg-primary-05': shallowValue !== item.value && !isHeading,
      },
      itemClasses,
    ]"
    @click.stop.prevent="select(item)"
    @keydown.enter="select(item)"
  >
    <slot>
      <h5 v-if="isHeading" class="text-subhead-3 mt-1 text-primary-80" :class="headingClasses">
        {{ item.text }}
      </h5>
      <div v-else class="flex w-full flex-row items-center gap-2">
        <UiFlagIcon v-if="item.icon && flag" :name="item.icon" />
        <UiAvatar v-else-if="avatar" :user="item" classes="w-5 min-w-5 h-5 text-subhead-3 !text-xs" />
        <UiIcon v-else-if="item.icon" :name="item.icon" />
        <small class="[overflow-wrap:anywhere]">
          {{ item.text }}
        </small>
      </div>
      <small v-if="verified && item.hasVerifiedPhone" class="flex items-center gap-1">
        Phone<UiIcon name="status-done" class="inline-flex text-success-100" size="xxs" />
      </small>
      <UiIcon v-if="modelValue === item.value" name="check" class="shrink-0"></UiIcon>
    </slot>
  </li>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import type { InputItem } from '@/types'

const emits = defineEmits(['input'])

type Props = {
  modelValue: any
  shallowValue: any
  item: InputItem
  flag?: boolean
  avatar?: boolean
  divider?: boolean
  headingClasses?: HTMLAttributes['class']
  itemClasses?: HTMLAttributes['class']
  verified?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  divider: true,
  headingClasses: '',
  itemClasses: '',
})

const isHeading = computed(() => props.item.value === 'heading')

const select = (item: InputItem) => {
  if (item.disabled || isHeading.value) return
  emits('input', item.value)
}
</script>

<style scoped></style>
