<template>
  <li :id="`activity-${modelValue.id}-${pinView}`" class="group">
    <div
      ref="timelineItem"
      class="relative rounded-xl border border-solid border-black-10 p-3 transition-colors duration-500 sm:rounded-2xl"
      :class="[{ 'bg-primary-05': !isItemViewed, 'bg-secondary-05': pinView }]"
    >
      <div class="flex items-center gap-x-2">
        <div v-if="modelValue.created_at" class="text-caption flex flex-row flex-wrap items-center gap-2 text-black-70">
          <div class="size-6 rounded-full bg-primary-05 p-1">
            <UiIcon
              :name="itemsTypeMap.get(modelValue.type.code)?.icon || 'info-circle'"
              size="xxs"
              class="text-primary-100"
            ></UiIcon>
          </div>
          <!-- custom component for headline after type icon and before the timestamp -->
          <component
            :is="itemsTypeMap.get(modelValue.type.code)?.headlineComponent"
            v-if="itemsTypeMap.get(modelValue.type.code)?.headlineComponent"
            :model-value="modelValue"
          />
          <div class="flex flex-row items-center">
            <div class="flex flex-row items-center gap-1">
              {{ itemsTypeMap.get(modelValue.type.code)?.name }}
              <!-- custom type title like "Call by {author}" -->
              <component
                :is="itemsTypeMap.get(modelValue.type.code)?.typeTitleComponent(modelValue)"
                v-if="itemsTypeMap.get(modelValue.type.code)?.typeTitleComponent"
              />
            </div>
            <span v-if="!itemsTypeMap.get(modelValue.type.code)?.hideTimestamp"> , </span>
          </div>
          <template v-if="!itemsTypeMap.get(modelValue.type.code)?.hideTimestamp">
            <span v-if="isToday(new Date(modelValue.created_at))">Today |</span>
            {{ format(new Date(modelValue.created_at), `d MMMM yyyy, HH:mm`) }}
          </template>
        </div>
      </div>

      <div class="text-body-2" :class="{ 'pt-2': !itemsTypeMap.get(modelValue.type.code)?.hasActions }">
        <component
          :is="itemsTypeMap.get(modelValue.type.code)?.component"
          v-if="itemsTypeMap.get(modelValue.type.code)"
          :model-value="modelValue"
          @update:model-value="emits('update:modelValue', $event)"
        ></component>
      </div>
      <UiTooltip v-if="showPin" :name="`pin_disabled-${modelValue.id}-${pinView}`" class="absolute right-4 top-4">
        <template #activator>
          <button
            :id="`pin-${modelValue.id}`"
            class="rounded-md text-black-70 opacity-0 transition-all hover:bg-primary-10 group-hover:opacity-100 group-[.driver-active-element]:!text-primary-100 group-[.driver-active-element]:!opacity-100 [&.driver-active-element]:!opacity-100"
            :class="[
              { '!text-primary-100 opacity-100': modelValue.is_pinned },
              { 'pointer-events-none !text-black-30': disablePin && !modelValue.is_pinned },
            ]"
            :disabled="updatingActivity"
            @click="pinActivity"
          >
            <UiIcon v-if="modelValue.is_pinned" name="pin-filled" class="text-primary-100" />
            <UiIcon v-else name="pin" />
          </button>
        </template>
        <template v-if="disablePin && !modelValue.is_pinned" #content
          >You can have only 5 pinned notes at once. Please unpin one to add extra.</template
        >
      </UiTooltip>
    </div>
  </li>
</template>

<script setup lang="ts">
import { format, isToday } from 'date-fns'
import type { Component } from 'nuxt/schema'
import { useUiStore } from '~/store/ui'
import type { TimelineItem } from '@/types'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'update:pin'])

type Props = {
  modelValue: TimelineItem
  module: 'lead' | 'deal'
  canViewHighlightedTimeline?: boolean
  pinView?: boolean
  disablePin?: boolean
  showPin?: boolean
}
const props = defineProps<Props>()

const vModel = defineModel<TimelineItem>('modelValue', {
  required: true,
})

type Item = {
  name?: string
  icon: string
  typeTitleComponent?: Function
  headlineComponent?: Component
  component: Component
  hasActions?: boolean
  viewMode?: boolean
  hideTimestamp?: boolean
}

enum LEAD_TYPES {
  REFERRAL_COMMENT = 'referral_comment',
  REFERRAL_NOTE = 'referral_note',
  NOTE = 'note',
  REFERRAL_INFO_UPDATED = 'referral_info_updated',
  CALL = 'call',
  INFORMATION = 'information',
  TRANSITION = 'transition',
  CALENDAR_TASK = 'calendar_task',
  CALENDAR_MEETING = 'calendar_meeting',
  CALENDAR_CALL = 'calendar_call',
  CALENDAR_ALL_DAY = 'calendar_all_day',
  LEAD_CHANGES = 'lead_changes',
  PAYMENT_TOKEN = 'payment_token',
}

const leadTypesMaps = new Map<string, Item>([
  [
    LEAD_TYPES.REFERRAL_COMMENT,
    {
      name: 'System note',
      icon: 'edit-page',
      component: resolveComponent('UiTimelineItemLeadReferralComment') as Component,
    },
  ],
  [
    LEAD_TYPES.PAYMENT_TOKEN,
    {
      name: 'System note',
      icon: 'info-circle',
      component: resolveComponent('UiTimelineItemLeadPaymentToken') as Component,
    },
  ],
  [
    LEAD_TYPES.REFERRAL_NOTE,
    {
      name: 'Note',
      icon: 'edit-page',
      component: resolveComponent('UiTimelineItemLeadReferralNote') as Component,
      viewMode: true,
    },
  ],
  [
    LEAD_TYPES.NOTE,
    {
      name: 'Note',
      icon: 'edit-page',
      typeTitleComponent: (modelValue: TimelineItem) =>
        h('div', {
          class: 'text-caption',
          innerHTML: `<span class="font-normal">by</span>
               ${modelValue.user?.name}</span
            >`,
        }),
      component: resolveComponent('UiTimelineItemLeadNote') as Component,
      hasActions: true,
    },
  ],
  [
    LEAD_TYPES.REFERRAL_INFO_UPDATED,
    {
      name: 'System note',
      icon: 'edit-page',
      component: resolveComponent('UiTimelineItemLeadReferralInfoUpdated') as Component,
      viewMode: true,
    },
  ],
  [
    LEAD_TYPES.CALL,
    {
      name: 'Call',
      icon: 'call',
      typeTitleComponent: (modelValue: TimelineItem) =>
        h('div', {
          class: 'text-caption',
          innerHTML: `<span class="font-normal">${modelValue.direction === 'inbound' ? 'to' : 'by'}</span>
               ${modelValue.user?.name}</span
            >`,
        }),
      component: resolveComponent('UiTimelineItemLeadCall') as Component,
      headlineComponent: resolveComponent('UiTimelineItemLeadCallHeadline') as Component,
    },
  ],
  [
    LEAD_TYPES.INFORMATION,
    {
      name: 'System note',
      icon: 'info-circle',
      component: resolveComponent('UiTimelineItemLeadInformation') as Component,
    },
  ],
  [
    LEAD_TYPES.TRANSITION,
    {
      name: 'System note',
      icon: 'operations-forward',
      component: resolveComponent('UiTimelineItemLeadTransition') as Component,
    },
  ],
  [
    LEAD_TYPES.CALENDAR_TASK,
    {
      icon: 'calendar',
      component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component,
      headlineComponent: resolveComponent('UiTimelineItemLeadCalendarActivityHeadline') as Component,
      hideTimestamp: true,
    },
  ],
  [
    LEAD_TYPES.CALENDAR_MEETING,
    {
      icon: 'calendar',
      component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component,
      headlineComponent: resolveComponent('UiTimelineItemLeadCalendarActivityHeadline') as Component,
      hideTimestamp: true,
    },
  ],
  [
    LEAD_TYPES.CALENDAR_CALL,
    {
      icon: 'calendar',
      component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component,
      headlineComponent: resolveComponent('UiTimelineItemLeadCalendarActivityHeadline') as Component,
      hideTimestamp: true,
    },
  ],
  [
    LEAD_TYPES.CALENDAR_ALL_DAY,
    {
      icon: 'calendar',
      component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component,
      headlineComponent: resolveComponent('UiTimelineItemLeadCalendarActivityHeadline') as Component,
      hideTimestamp: true,
    },
  ],
  [
    LEAD_TYPES.LEAD_CHANGES,
    { name: 'System note', icon: 'info-circle', component: resolveComponent('UiTimelineItemLeadChanges') as Component },
  ],
])

enum DEAL_TYPES {
  TRANSITION = 'transition',
  NOTE = 'note',
  INFO_CHANGE = 'info_change',
}

const dealTypesMap = new Map<string, Item>([
  [
    DEAL_TYPES.TRANSITION,
    {
      name: 'System note',
      icon: 'operations-forward',
      component: resolveComponent('UiTimelineItemDealTransition') as Component,
    },
  ],
  [
    DEAL_TYPES.NOTE,
    {
      name: 'Note',
      icon: 'info-circle',
      component: resolveComponent('UiTimelineItemDealNote') as Component,
      hasActions: true,
    },
  ],
  [
    DEAL_TYPES.INFO_CHANGE,
    {
      name: 'System note',
      icon: 'edit-page',
      component: resolveComponent('UiTimelineItemDealInfoChange') as Component,
    },
  ],
])

const modules = new Map<string, Map<string, Item>>([
  ['lead', leadTypesMaps],
  ['deal', dealTypesMap],
])

const itemsTypeMap = modules.get(props.module) || leadTypesMaps

const currentItem = computed(() => itemsTypeMap.get(props.modelValue.type.code))

const isItemViewed = computed(() => (isViewMode.value ? props.modelValue.is_viewed : true))

const isViewMode = computed(() => currentItem.value?.viewMode && props.canViewHighlightedTimeline)

const isTimelineNote = computed(() => props.modelValue.type.code === LEAD_TYPES.NOTE)

const timelineItem = ref(null)

const updatingActivity = ref(false)

const { stop } = useIntersectionObserver(
  isViewMode.value ? timelineItem : null,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      useViewActivity({
        id: props.modelValue.id,
        isViewed: true,
      })
        .then(({ data }) => {
          vModel.value.is_viewed = Boolean(data.value?.data.is_viewed)
          stop()
        })
        .catch((error: any) => {
          uiStore.showSnackBanner(error.message, 'error')
        })
    }
  },
  {
    threshold: 0.3,
  }
)

onMounted(() => {
  if (!isViewMode.value) {
    stop()
  }
})

const pinActivity = async () => {
  try {
    updatingActivity.value = true
    const value = !props.modelValue.is_pinned
    await useUpdateLeadActivity(props.modelValue.id, { is_pinned: value })
    vModel.value = { ...vModel.value, is_pinned: value }
    emits('update:pin', vModel.value)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    updatingActivity.value = false
  }
}
</script>

<style scoped></style>
