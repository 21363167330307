import { ENVS, FEATURE_FLAGS } from '~/constants'

export const useFeatureFlag = (key: FEATURE_FLAGS) => {
  const runtimeConfig = useRuntimeConfig()

  const flags = {
    [FEATURE_FLAGS.PAYMENT_TOKENS]: [ENVS.DEV, ENVS.STAGING, ENVS.PROD].includes(runtimeConfig.public.APP_ENV),
  }

  return flags[key]
}
