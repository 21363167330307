import { defineStore } from 'pinia'
import type {
  Country,
  Language,
  Timezone,
  Currency,
  Location,
  User,
  Status,
  Pipeline,
  LibraryItem,
  Outcome,
  Tag,
  InputItem,
} from '@/types'
import type { DICTIONARIES } from '~/constants'

export const useLibrariesStore = defineStore('libraries', {
  state: () => ({
    pipelines: [] as Pipeline[],
    countries: [] as Country[],
    languages: [] as Language[],
    timezones: [] as Timezone[],
    creationLeadStrategies: [] as LibraryItem[],
    sourceLeadStrategies: [] as LibraryItem[],
    spamStatuses: [] as LibraryItem[],
    spamServices: [] as LibraryItem[],
    currencies: [] as Currency[],
    interests: [] as Interest[],
    locations: [] as Location[],
    resolutions: [] as LibraryItem[],
    callOutboundOutcomes: [] as Outcome[],
    propertyTypes: [] as LibraryItem[],
    users: [] as User[],
    allUsers: [] as User[],
    allUsersList: [] as User[],
    statuses: [] as Status[],
    bedrooms: [] as LibraryItem[],
    dashboardRanges: [] as LibraryItem[],
    calendarActivityTypes: [] as LibraryItem[],
    calendarActivityStatuses: [] as LibraryItem[],
    notificationTypes: [] as LibraryItem[],
    tags: [] as Tag[],
    dealTags: [] as Tag[],
    financeDealStages: [] as LibraryItem[],
    financeDealStatuses: [] as LibraryItem[],
    financeDealSources: [] as LibraryItem[],
    financeDealTypes: [] as LibraryItem[],
    financeDealReasons: [] as LibraryItem[],
    financeDealActionTypes: [] as LibraryItem[],
    financeDealActionStatuses: [] as LibraryItem[],
    financeProjects: [] as LibraryItem[],
    financeDevelopers: [] as LibraryItem[],
    financeBuyers: [] as LibraryItem[],
    financeFileTypes: [] as LibraryItem[],
    financeCommissionTypes: [] as LibraryItem[],
    financeCommissionStatuses: [] as LibraryItem[],
    financeAgreementTypes: [] as LibraryItem[],
    financeAgreementStatuses: [] as LibraryItem[],
    financePaymentInvoiceStatuses: [] as LibraryItem[],
    financeCommissionCalculationTypes: [] as LibraryItem[],
    financeContactTypes: [] as LibraryItem[],
    financeCommissionsGroupStatuses: [] as LibraryItem[],
    financeDealVerificationsStatuses: [] as LibraryItem[],
    pbxCallDirections: [] as LibraryItem[],
    activityActionsTaken: [] as LibraryItem[],
    propertiesBuildingStatuses: [] as LibraryItem[],
    propertiesLocations: [] as LibraryItem[],
    propertiesRoomsCount: [] as LibraryItem[],
    propertiesSaleStatuses: [] as LibraryItem[],
    propertiesTypes: [] as LibraryItem[],
    propertiesAmenities: [] as LibraryItem[],
    usersAvailabilityStatuses: [] as LibraryItem[],
    partners: [] as LibraryItem[],
    meetingOutcomeStatuses: [] as LibraryItem[],
    meetingOutcomeResolutions: [] as LibraryItem[],
    leadPhoneTypes: [] as LibraryItem[],
    dashboardReports: [] as LibraryItem[],
    releaseComponentTypes: [] as LibraryItem[],
    paymentTokenTypes: [] as LibraryItem[],
    developerNames: [] as LibraryItem[],
    propertyCatalogLibraries: {} as {
      [K in DICTIONARIES]?: LibraryItem[]
    },
    libraries: {} as {
      [K in DICTIONARIES]?: InputItem[]
    },
    mappingRotationTypes: [] as LibraryItem[],
    utmSources: [] as LibraryItem[],
    channels: [] as LibraryItem[],
    callHandlers: [] as LibraryItem[],
    localPhoneTypes: [] as LibraryItem[],
    propertyProjects: [] as LibraryItem[],
  }),
  actions: {
    addPipeline(pipeline: Pipeline) {
      this.pipelines.push(pipeline)
    },
    setCountries(countries: Country[]) {
      this.countries = countries
    },
    setLanguages(languages: Language[]) {
      this.languages = languages
    },
    setTimezones(timezones: Timezone[]) {
      this.timezones = timezones
    },
    setStrategies(strategies: LibraryItem[]) {
      this.creationLeadStrategies = strategies
    },
    setSourceLeadStrategies(strategies: LibraryItem[]) {
      this.sourceLeadStrategies = strategies
    },
    setSpamStatuses(spamStatuses: LibraryItem[]) {
      this.spamStatuses = spamStatuses
    },
    setSpamServices(spamServices: LibraryItem[]) {
      this.spamServices = spamServices
    },
    setCurrencies(currencies: Currency[]) {
      this.currencies = currencies
    },
    setInterests(interests: Interest[]) {
      this.interests = interests
    },
    setLocations(locations: Location[]) {
      this.locations = locations
    },
    setResolutions(resolutions: LibraryItem[]) {
      this.resolutions = resolutions
    },
    setPropertyTypes(propertyTypes: LibraryItem[]) {
      this.propertyTypes = propertyTypes
    },
    setUsers(users: User[]) {
      this.users = users
    },
    setAllUsers(users: User[]) {
      this.allUsers = users
    },
    setAllUsersList(users: User[]) {
      this.allUsersList = users
    },
    setStatuses(statuses: Status[]) {
      this.statuses = statuses
    },
    setBedrooms(bedrooms: LibraryItem[]) {
      this.bedrooms = bedrooms
    },
    setDashboardRanges(dashboardRanges: LibraryItem[]) {
      this.dashboardRanges = dashboardRanges
    },
    setCalendarActivityTypes(calendarActivityTypes: LibraryItem[]) {
      this.calendarActivityTypes = calendarActivityTypes
    },
    setCalendarActivityStatuses(calendarActivityStatuses: LibraryItem[]) {
      this.calendarActivityStatuses = calendarActivityStatuses
    },
    setNotificationTypes(notificationTypes: LibraryItem[]) {
      this.notificationTypes = notificationTypes
    },
    setTags(tags: Tag[]) {
      this.tags = tags
    },
    setTagsDeal(tags: Tag[]) {
      this.dealTags = tags
    },
    setCallOutboundOutcomes(callOutboundOutcomes: Outcome[]) {
      this.callOutboundOutcomes = callOutboundOutcomes
    },
    setFinanceDealStages(financeDealStages: LibraryItem[]) {
      this.financeDealStages = financeDealStages
    },
    setFinanceDealStatuses(financeDealStatuses: LibraryItem[]) {
      this.financeDealStatuses = financeDealStatuses
    },
    setFinanceDealSources(financeDealSources: LibraryItem[]) {
      this.financeDealSources = financeDealSources
    },
    setFinanceDealTypes(financeDealTypes: LibraryItem[]) {
      this.financeDealTypes = financeDealTypes
    },
    setFinanceDealReasons(financeDealReasons: LibraryItem[]) {
      this.financeDealReasons = financeDealReasons
    },
    setFinanceDealActionTypes(financeDealActionTypes: LibraryItem[]) {
      this.financeDealActionTypes = financeDealActionTypes
    },
    setFinanceDealActionStatuses(financeDealActionStatuses: LibraryItem[]) {
      this.financeDealActionStatuses = financeDealActionStatuses
    },
    setFinanceProjects(financeProjects: LibraryItem[]) {
      this.financeProjects = financeProjects
    },
    setFinanceDevelopers(financeDevelopers: LibraryItem[]) {
      this.financeDevelopers = financeDevelopers
    },
    setFinanceBuyers(financeBuyers: LibraryItem[]) {
      this.financeBuyers = financeBuyers
    },
    setFinanceFileTypes(financeFileTypes: LibraryItem[]) {
      this.financeFileTypes = financeFileTypes
    },
    setFinanceCommissionTypes(financeCommissionTypes: LibraryItem[]) {
      this.financeCommissionTypes = financeCommissionTypes
    },
    setFinanceCommissionStatuses(financeCommissionStatuses: LibraryItem[]) {
      this.financeCommissionStatuses = financeCommissionStatuses
    },
    setFinanceAgreementTypes(financeAgreementTypes: LibraryItem[]) {
      this.financeAgreementTypes = financeAgreementTypes
    },
    setFinanceAgreementStatuses(financeAgreementStatuses: LibraryItem[]) {
      this.financeAgreementStatuses = financeAgreementStatuses
    },
    setFinancePaymentInvoiceStatuses(financePaymentInvoiceStatuses: LibraryItem[]) {
      this.financePaymentInvoiceStatuses = financePaymentInvoiceStatuses
    },
    setFinanceContactTypes(financeContactTypes: LibraryItem[]) {
      this.financeContactTypes = financeContactTypes
    },
    setFinanceCommissionsGroupStatuses(financeCommissionsGroupStatuses: LibraryItem[]) {
      this.financeCommissionsGroupStatuses = financeCommissionsGroupStatuses
    },
    setPbxCallDirections(pbxCallDirections: LibraryItem[]) {
      this.pbxCallDirections = pbxCallDirections
    },
    setActivityActionsTaken(activityActionsTaken: LibraryItem[]) {
      this.activityActionsTaken = activityActionsTaken
    },
    setPropertiesBuildingStatuses(buildingStatuses: LibraryItem[]) {
      this.propertiesBuildingStatuses = buildingStatuses
    },
    setPropertiesLocations(locations: LibraryItem[]) {
      this.propertiesLocations = locations
    },
    setPropertiesRoomsCount(roomsCount: LibraryItem[]) {
      this.propertiesRoomsCount = roomsCount
    },
    setPropertiesSaleStatuses(salesStatuses: LibraryItem[]) {
      this.propertiesSaleStatuses = salesStatuses
    },
    setPropertiesTypes(types: LibraryItem[]) {
      this.propertiesTypes = types
    },
    setPropertiesAmenities(amenities: LibraryItem[]) {
      this.propertiesAmenities = amenities
    },
    setFinanceCommissionCalculationTypes(financeCommissionCalculationTypes: LibraryItem[]) {
      this.financeCommissionCalculationTypes = financeCommissionCalculationTypes
    },
    setFinanceDealVerificationsStatuses(financeDealVerificationsStatuses: LibraryItem[]) {
      this.financeDealVerificationsStatuses = financeDealVerificationsStatuses
    },
    setUsersAvailabilityStatuses(usersAvailabilityStatuses: LibraryItem[]) {
      this.usersAvailabilityStatuses = usersAvailabilityStatuses
    },
    setPartners(partners: LibraryItem[]) {
      this.partners = partners
    },
    setMeetingOutcomeStatuses(statuses: LibraryItem[]) {
      this.meetingOutcomeStatuses = statuses
    },
    setMeetingOutcomeResolutions(resolutions: LibraryItem[]) {
      this.meetingOutcomeResolutions = resolutions
    },
    setLeadPhoneTypes(types: LibraryItem[]) {
      this.leadPhoneTypes = types
    },
    setDashboardReports(dashboardReports: LibraryItem[]) {
      this.dashboardReports = dashboardReports
    },
    setReleaseComponentTypes(types: LibraryItem[]) {
      this.releaseComponentTypes = types
    },
    setPaymentTokenTypes(types: LibraryItem[]) {
      this.paymentTokenTypes = types
    },
    setDeveloperNames(developerNames: LibraryItem[]) {
      this.developerNames = developerNames
    },
    setMappingRotationTypes(types: LibraryItem[]) {
      this.mappingRotationTypes = types
    },
    setUtmSources(sources: LibraryItem[]) {
      this.utmSources = sources
    },
    setFinanceDealChannels(channels: LibraryItem[]) {
      this.channels = channels
    },
    setCallHandlers(types: LibraryItem[]) {
      this.callHandlers = types
    },
    setLocalPhoneTypes(types: LibraryItem[]) {
      this.localPhoneTypes = types
    },
    setPropertyProjects(projects: LibraryItem[]) {
      this.propertyProjects = projects
    },
  },
  getters: {
    getPipelines: (state) => state.pipelines,
    getCountries: (state) => state.countries,
    getCountryById: (state) => {
      return (id: number) => state.countries.find((country) => country.id === id)
    },
    getLanguages: (state) => state.languages,
    getTimezones: (state) => state.timezones,
    getStrategies: (state) => state.creationLeadStrategies,
    getSourceLeadStrategies: (state) => state.sourceLeadStrategies,
    getSpamStatuses: (state) => state.spamStatuses,
    getSpamServices: (state) => state.spamServices,
    getCurrencies: (state) => state.currencies,
    getInterests: (state) => state.interests,
    getLocations: (state) => state.locations,
    getResolutions: (state) => state.resolutions,
    getPropertyTypes: (state) => state.propertyTypes,
    getUsers: (state) => state.users,
    getAllUsers: (state) => state.allUsers,
    getAllUsersList: (state) => state.allUsersList,
    getStatuses: (state) => state.statuses,
    getBedrooms: (state) => state.bedrooms,
    getDashboardRanges: (state) => state.dashboardRanges,
    getCalendarActivityTypes: (state) => state.calendarActivityTypes,
    getCalendarActivityStatuses: (state) => state.calendarActivityStatuses,
    getNotificationTypes: (state) => state.notificationTypes,
    getTags: (state) => state.tags,
    getDealTags: (state) => state.dealTags,
    getCallOutboundOutcomes: (state) => state.callOutboundOutcomes,
    getFinanceDealStages: (state) => state.financeDealStages,
    getFinanceDealStatuses: (state) => state.financeDealStatuses,
    getFinanceDealSources: (state) => state.financeDealSources,
    getFinanceDealTypes: (state) => state.financeDealTypes,
    getFinanceDealReasons: (state) => state.financeDealReasons,
    getFinanceDealActionTypes: (state) => state.financeDealActionTypes,
    getFinanceDealActionStatuses: (state) => state.financeDealActionStatuses,
    getFinanceProjects: (state) => state.financeProjects,
    getFinanceDevelopers: (state) => state.financeDevelopers,
    getFinanceBuyers: (state) => state.financeBuyers,
    getFinanceFileTypes: (state) => state.financeFileTypes,
    getFinanceCommissionTypes: (state) => state.financeCommissionTypes,
    getFinanceCommissionStatuses: (state) => state.financeCommissionStatuses,
    getFinanceAgreementTypes: (state) => state.financeAgreementTypes,
    getFinanceAgreementStatuses: (state) => state.financeAgreementStatuses,
    getFinancePaymentInvoiceStatuses: (state) => state.financePaymentInvoiceStatuses,
    getFinanceContactTypes: (state) => state.financeContactTypes,
    getFinanceCommissionsGroupStatuses: (state) => state.financeCommissionsGroupStatuses,
    getFinanceDealVerificationsStatuses: (state) => state.financeDealVerificationsStatuses,
    getPbxCallDirections: (state) => state.pbxCallDirections,
    getActivityActionsTaken: (state) => state.activityActionsTaken,
    getFinanceCommissionCalculationTypes: (state) => state.financeCommissionCalculationTypes,
    getPropertiesBuildingStatuses: (state) => state.propertiesBuildingStatuses,
    getPropertiesLocations: (state) => state.propertiesLocations,
    getPropertiesRoomsCount: (state) => state.propertiesRoomsCount,
    getPropertiesSaleStatuses: (state) => state.propertiesSaleStatuses,
    getPropertiesTypes: (state) => state.propertiesTypes,
    getPropertiesAmenities: (state) => state.propertiesAmenities,
    getUsersAvailabilityStatuses: (state) => state.usersAvailabilityStatuses,
    getPartners: (state) => state.partners,
    getMeetingOutcomeStatuses: (state) => state.meetingOutcomeStatuses,
    getMeetingOutcomeResolutions: (state) => state.meetingOutcomeResolutions,
    getLeadPhoneTypes: (state) => state.leadPhoneTypes,
    getDashboardReports: (state) => state.dashboardReports,
    getReleaseComponentTypes: (state) => state.releaseComponentTypes,
    getPaymentTokenTypes: (state) => state.paymentTokenTypes,
    getDeveloperNames: (state) => state.developerNames,
    getMappingRotationTypes: (state) => state.mappingRotationTypes,
    getUtmSources: (state) => state.utmSources,
    getFinanceDealChannels: (state) => state.channels,
    getCallHandlers: (state) => state.callHandlers,
    getLocalPhoneTypes: (state) => state.localPhoneTypes,
    getPropertyProjects: (state) => state.propertyProjects,
  },
})
