<template>
  <div class="relative flex flex-col gap-4">
    <div class="flex flex-col gap-3 rounded-3xl bg-white p-6">
      <div class="flex items-center justify-between">
        <h5>Lead details</h5>
        <button v-if="!readonly && !isClosed" @click="showEditDetailsSidePanel">
          <UiIcon name="edit" class="text-black-70" size="xs" />
        </button>
      </div>
      <template v-if="isContactsBlockVisible">
        <div v-if="isCommunicationWayPartner && lead.partner" class="flex flex-row items-center gap-4">
          <small class="w-1/3 text-black-60"> Partner phone number primary </small>
          <LeadInfoDetailsPhoneNumber :phone="lead.partner.phone" :phone-country="lead.partner.phone_country" :lead />
        </div>
        <div
          v-else-if="isCommunicationWayRepresentative && lead.representative"
          class="flex flex-row items-center gap-4"
        >
          <small class="w-1/3 text-black-60">Representative phone number primary </small>
          <LeadInfoDetailsPhoneNumber
            :phone="lead.representative.phone"
            :phone-country="lead.representative.phone_country"
            :lead
          />
        </div>
        <div class="flex flex-row items-center gap-4">
          <small class="w-1/3 text-black-60">
            {{
              isCommunicationWayPartner || isCommunicationWayRepresentative
                ? 'Lead phone number secondary'
                : 'Phone number primary'
            }}
          </small>
          <LeadInfoDetailsPhoneNumber :phone="lead.phone" :phone-country="lead.phone_country" :lead />
        </div>
      </template>
      <div v-if="lead.email" class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> Email </small>
        <small class="w-2/3 text-black-100" :class="{ 'blur-sm': modelValue.email === HIDDEN }">
          {{ modelValue.email || '-' }}
        </small>
      </div>
      <div class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> Country </small>
        <small class="w-2/3 text-black-100">
          {{ modelValue.country?.name || '-' }}
        </small>
      </div>
      <div class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> Language </small>
        <small class="w-2/3 text-black-100">
          {{ modelValue.language?.name || '-' }}
        </small>
      </div>
      <div v-if="modelValue.become_dic_at" class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> DIC member from </small>
        <small class="w-2/3 text-black-100">
          {{ format(new Date(modelValue.become_dic_at), 'dd MMM yyyy') }}
        </small>
      </div>
      <div class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> Local time </small>
        <small class="w-2/3 text-black-100">
          {{ useGetLocalTime(modelValue.timezone) || '-' }}
        </small>
      </div>
    </div>
    <div class="flex flex-col gap-3 rounded-3xl bg-white p-6">
      <div class="flex items-center justify-between">
        <h5>Lead interests</h5>
        <button v-if="tags.length && !readonly && !isPoolLead && !isClosed" @click="showEditInterestsSidePanel">
          <UiIcon name="edit" class="text-black-70" size="xs" />
        </button>
      </div>
      <div v-if="tags.length" class="flex flex-row flex-wrap gap-2">
        <UiTagSecondary
          v-for="(tag, index) in tags"
          :id="`tag_${index}`"
          :key="index"
          :show-tooltip="!!tag.tooltip"
          class="[&>div]:!h-7 [&>div]:!py-1"
        >
          <template #content>
            {{ tag.text }}
          </template>
          <template #tooltip>
            {{ tag.tooltip }}
          </template>
        </UiTagSecondary>
      </div>
      <UiButtonBase
        v-else-if="!readonly && !isPoolLead && !isClosed"
        id="add_interests"
        class="mt-2 !w-full"
        type="secondary"
        @click="showEditInterestsSidePanel"
        >Add lead interests
      </UiButtonBase>
    </div>
    <div v-if="isCommunicationWayPartner && modelValue.partner" class="flex flex-col gap-3 rounded-3xl bg-white p-6">
      <div class="flex items-center justify-between">
        <h5>Partner details</h5>
      </div>
      <div class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> Name </small>
        <small class="w-2/3 truncate text-black-100">
          {{ partner?.name }}
        </small>
      </div>
      <div class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> Language </small>
        <small class="w-2/3 text-black-100">
          {{ partner?.language?.text || '-' }}
        </small>
      </div>
      <div class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> Email </small>
        <small class="w-2/3 truncate text-black-100">
          {{ partner?.email || '-' }}
        </small>
      </div>
    </div>
    <div
      v-else-if="isCommunicationWayRepresentative && modelValue.representative"
      class="flex flex-col gap-3 rounded-3xl bg-white p-6"
    >
      <div class="flex items-center justify-between">
        <h5>Representative details</h5>
      </div>
      <div class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> Name </small>
        <small class="w-2/3 truncate text-black-100">
          {{ modelValue.representative?.name }}
        </small>
      </div>
    </div>
    <div class="flex flex-col gap-3 rounded-3xl bg-white p-6">
      <div class="flex items-center justify-between">
        <h5>Assignee</h5>
      </div>
      <div class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> SDR </small>
        <small class="flex w-2/3 items-center gap-2 text-black-100">
          <UiIcon
            v-if="isLeadAssigned && getPreviousAssignee(ROLES.SDR)?.id === assigneeId"
            name="check-filled"
            class="fill-white text-additional-2-120"
          />

          {{ getPreviousAssignee(ROLES.SDR)?.name || '-' }}
        </small>
      </div>
      <div class="flex flex-row items-center gap-4">
        <small class="w-1/3 text-black-60"> Agent </small>
        <small class="flex w-2/3 items-center gap-2 text-black-100">
          <UiIcon
            v-if="isLeadAssigned && getPreviousAssignee(ROLES.AGENT)?.id === assigneeId"
            name="check-filled"
            class="fill-white text-additional-2-120"
          />

          {{ getPreviousAssignee(ROLES.AGENT)?.name || '-' }}
        </small>
      </div>
      <div
        v-if="!isSDR(lead.assignee?.role.code) && !isAgent(lead.assignee?.role.code) && isLeadAssigned"
        class="flex flex-row items-center gap-4"
      >
        <small class="w-1/3 text-black-60"> User </small>
        <small class="flex w-2/3 items-center gap-2 text-black-100">
          <UiIcon name="check-filled" class="fill-white text-additional-2-120" />
          {{ lead.assignee?.name || '-' }}
        </small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { CUSTOM_EVENTS, HIDDEN, POOL_STAGES, ROLES } from '@/constants'
import { SIDE_PANELS } from '@/components/dynamic/maps'
import type { InputItem, Lead, LeadPartner } from '@/types'

import { useUiStore } from '@/store/ui'
import { useAuthStore } from '@/store/auth'
import { isAgent, isSDR } from '~/guards/roles'

const uiStore = useUiStore()
const authStore = useAuthStore()

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: Lead
  readonly?: boolean
}
const props = defineProps<Props>()
const lead = useVModel(props, 'modelValue', emits)

const isPoolLead = computed(() => Object.values(POOL_STAGES).includes(lead.value.stage?.code as string))

const tags = computedAsync(async () => await useParseInterests(props.modelValue), [])

const isShadowLead = inject<Ref<boolean>>('isShadowLead')
const isClosed = inject<Ref<boolean>>('isClosed')

const isContactsBlockVisible = computed(() => {
  if (!useGetShowPhoneLead(lead.value)) return false

  if (isShadowLead?.value && (authStore.getIsAdmin || authStore.getIsSalesManager)) return false

  return lead.value.phone_country && lead.value.phone
})

const isLeadAssigned = computed(() => lead.value.assignee?.id)

const isCommunicationWayPartner = inject<Ref<boolean>>('isCommunicationWayPartner')
const isCommunicationWayRepresentative = inject<Ref<boolean>>('isCommunicationWayRepresentative')

const assigneeId = computed(() => {
  return lead.value.assignee?.id
})

const getPreviousAssignee = (role: (typeof ROLES)[keyof typeof ROLES]) => {
  if (!isSDR(role) && !isAgent(role)) {
    throw new Error('Function executed with wrong role')
  }

  if (lead.value.assignee?.role.code === role) {
    return lead.value.assignee
  }

  const key = isSDR(role) ? 'prev_sdr_assignee' : 'prev_agent_assignee'

  if (lead.value[key]?.role.code === role) {
    return lead.value[key]
  }

  return undefined
}

const showEditInterestsSidePanel = () => {
  uiStore.showSidePanel(
    SIDE_PANELS.LEAD_INFO_INTERESTS,
    { leadId: props.modelValue.id },
    {
      input: () => refreshLead(),
    }
  )
}

const showEditDetailsSidePanel = () => {
  uiStore.showSidePanel(
    SIDE_PANELS.LEAD_INFO_DETAILS,
    { leadId: props.modelValue.id },
    {
      input: () => refreshLead(),
    }
  )
}

const refreshLead = () => {
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_DATA))
}

const partner = inject<
  LeadPartner & {
    language: InputItem
  }
>('partner')
</script>

<style scoped></style>
