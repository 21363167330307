<template>
  <!-- Text Input -->
  <div :id="name" class="relative flex w-full flex-col items-start space-y-px">
    <div class="flex w-full flex-row items-center justify-between">
      <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
      <p v-if="limit && !limitBelow" class="text-caption-2 ml-auto text-black-60">
        {{ modelValue?.length || 0 }}/{{ maxLength }}
      </p>
    </div>
    <label class="relative flex min-h-[40px] w-full flex-row items-center justify-between gap-2 px-4 py-2.5">
      <textarea
        :id="name"
        :ref="(el) => (refs[name] = el)"
        v-model="shallowValue"
        :text="shallowValue"
        :name="name"
        :rows="startRows"
        :placeholder="placeholder"
        :disabled="disabled"
        class="text-body-2 peer z-10 flex-1 resize-none overflow-y-hidden border-none bg-transparent text-sm font-normal outline-none placeholder:text-sm placeholder:font-normal placeholder:leading-5"
        :class="[textAreaClass, { 'transition-all duration-200': !withoutTransition }]"
        :style="{ minHeight: `${startRows * 20}px` }"
        @focus=";(isInputFocused = true), modelValue || withoutTransition ? null : (refs[name].style.height = '40px')"
        @blur=";(isInputFocused = false), modelValue ? null : (refs[name].style.height = '20px')"
        @input="input($event)"
      />
      <div
        class="absolute left-0 z-0 size-full rounded-xl border-[1.5px] border-solid border-black-20 outline-none transition-colors duration-200 hover:border-primary-50 active:border-primary-120 peer-hover:border-primary-50 peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05"
        :class="[
          error
            ? 'border-error-100 peer-hover/icon-prefix:border-error-100 peer-hover/icon:border-error-100 peer-hover/prefix:border-error-100 peer-hover/suffix:border-error-100 peer-hover:border-error-100 '
            : 'border-black-20 hover:border-primary-50 active:border-primary-120 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50  peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05',
          { '!border-none': !error && ghost && !modelValue && !isInputFocused },
        ]"
      ></div>
    </label>
    <div class="absolute -bottom-4 h-4 w-full">
      <transition name="fade" mode="out-in">
        <p v-if="error" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-error-100">
          {{ error }}
        </p>
        <p v-else-if="description" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-black-60">
          {{ description }}
        </p>
      </transition>
      <p v-if="limit && limitBelow" class="text-caption-2 mt-1 text-right text-black-60">
        {{ modelValue?.length || 0 }}/{{ maxLength }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import { DEFAULT_DEBOUNCE_INPUT_TIME } from '@/constants'

const emits = defineEmits(['update:modelValue', 'update:debounced'])

type Props = {
  modelValue?: string
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  description?: string
  ghost?: boolean
  error?: string
  textAreaClass?: string
  limit?: boolean
  startRows?: number
  withoutTransition?: boolean
  maxLength?: number
  limitBelow?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  label: '',
  placeholder: '',
  description: '',
  error: '',
  textAreaClass: '',
  limit: true,
  startRows: 1,
  maxLength: 100,
})

const isInputFocused = ref(false)
const shallowValue = ref('')
watch(
  () => props.modelValue,
  () => {
    nextTick(() => {
      resizeTextArea()
    })
  }
)
watch(
  () => props.modelValue,
  (value: string) => {
    shallowValue.value = value
  },
  { immediate: true }
)

watchDebounced(
  shallowValue,
  () => {
    emits('update:debounced')
  },
  { debounce: DEFAULT_DEBOUNCE_INPUT_TIME }
)

const refs = {
  [props.name]: ref<HTMLInputElement>(),
}

const resizeTextArea = () => {
  refs[props.name].style.height = 'auto'
  refs[props.name].style.height = refs[props.name].scrollHeight + 'px'
}

const input = (event: Event) => {
  const target = event.target as HTMLInputElement

  if (props.limit && props.maxLength && target.value.length > props.maxLength) {
    shallowValue.value = target.value.slice(0, props.maxLength)
  } else {
    shallowValue.value = target.value
  }
  emits('update:modelValue', shallowValue.value)
  resizeTextArea()
}

onMounted(() => {
  nextTick(() => {
    resizeTextArea()
  })
})
</script>

<style scoped></style>
