<template>
  <div class="mr-1 flex flex-row items-center gap-2">
    <div
      v-if="callStatus"
      :class="statusTagClassesMap.get(callStatus)?.bgClass"
      class="flex h-6 items-center justify-center rounded-lg p-1"
    >
      <span :class="statusTagClassesMap.get(callStatus)?.textClass" class="text-caption">
        {{ statusTagClassesMap.get(callStatus)?.text }}
      </span>
    </div>
    <div
      class="flex w-fit flex-row items-center justify-between gap-1"
      :class="modelValue.direction === 'inbound' ? ' text-additional-2-120' : 'text-primary-100'"
    >
      <UiIcon v-if="modelValue.direction === 'inbound'" name="call-inbound" size="xxs" />
      <UiIcon v-else name="call-outgoing" size="xxs" />
      <span class="text-caption">
        {{ modelValue.direction === 'inbound' ? 'Incoming' : 'Outgoing' }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '~/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

enum STATUSES {
  BUSY = 'busy',
  NO_ANSWER = 'no-answer',
  COMPLETED = 'completed',
  VOICEMAIL = 'voicemail',
  ERROR = 'error',
}

const PBX_CODES = {
  BUSY: [486, 600],
  NO_ANSWER: [403, 603, 300, 301, 302, 305, 380, 480, 410, 408, 430, 487],
  ERROR: [484, 488, 606, 401, 407, 404, 604],
  COMPLETED: [200],
}

type TagProperties = {
  textClass: string
  bgClass: string
  text: string
}

const statusTagClassesMap = new Map<STATUSES, TagProperties>([
  [STATUSES.BUSY, { textClass: 'text-additional-3-100', bgClass: 'bg-additional-3-20', text: 'BUSY' }],
  [STATUSES.NO_ANSWER, { textClass: 'text-additional-1-120', bgClass: 'bg-additional-1-20', text: 'NO ANSWER' }],
  [STATUSES.COMPLETED, { textClass: 'text-additional-2-120', bgClass: 'bg-additional-2-20', text: 'SUCCESS' }],
  [STATUSES.VOICEMAIL, { textClass: 'text-primary-100', bgClass: 'bg-primary-20', text: 'VOICEMAIL' }],
  [STATUSES.ERROR, { textClass: 'text-error-100', bgClass: 'bg-additional-3-20', text: 'ERROR' }],
])

const getCallStatus = (
  answeredBy: string | undefined,
  outcomeCode: number | undefined,
  callStatus: string | undefined
): STATUSES | undefined => {
  if (answeredBy && answeredBy !== 'human') return STATUSES.VOICEMAIL
  if (outcomeCode !== undefined) {
    const code = Number(outcomeCode)
    if (PBX_CODES.BUSY.includes(code)) return STATUSES.BUSY
    if (PBX_CODES.NO_ANSWER.includes(code)) return STATUSES.NO_ANSWER
    if (PBX_CODES.ERROR.includes(code)) return STATUSES.ERROR
    if (PBX_CODES.COMPLETED.includes(code)) return STATUSES.COMPLETED
  }
  return callStatus as STATUSES
}

const callStatus = getCallStatus(
  props.modelValue.additional?.answered_by,
  props.modelValue.additional?.outcome_code,
  props.modelValue.additional?.CallStatus
)
</script>

<style scoped></style>
