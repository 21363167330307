<template>
  <div class="flex items-center gap-x-2">
    <div
      class="flex w-max items-center rounded-lg bg-additional-3-10 px-3 py-1.5 text-sm font-semibold text-additional-3-100"
    >
      <UiIcon name="alert-circle" class="mr-1 size-4"></UiIcon>
      Failure
    </div>
    <div>
      Lead with failed phone number was created. The original number is
      <span class="text-subhead-3">{{ failedItem?.value }}</span
      >.
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const failedItem = props.modelValue.additional.find((item) => item.field === 'failed_data')
</script>

<style scoped></style>
