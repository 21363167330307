<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row items-center gap-2">
      <PipelineLeadTag
        :id="`hot-lead-${props.modelValue.id}`"
        show-text
        :properties="useGetTagPropertiesByCode(TAGS.HOT_LEAD)!"
        :tag-code="TAGS.HOT_LEAD"
      />
      <div>
        2 leads were merged and <span class="text-subhead-3">HOT lead</span> was
        <span class="text-subhead-3">created!</span>
      </div>
    </div>

    <div class="flex flex-row gap-3">
      <div v-for="info in additionalItems" :key="info.field">
        <span class="text-caption-2 text-black-40">{{ info.field }}</span>
        <div class="flex flex-wrap gap-x-2 gap-y-1">
          <span>{{ info.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { TAGS } from '@/constants'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const additionalItems = props.modelValue.additional.filter((item) => item.value && item.field !== 'Hot lead')
</script>

<style scoped></style>
