<template>
  <div ref="avatar" class="relative flex flex-row items-center gap-4">
    <div class="relative cursor-pointer select-none" @click="showMenu = !showMenu">
      <UiAvatar :user="{ ...authStore.getUser, image: authStore.getUser.image }" />
      <div class="absolute bottom-0 right-0">
        <UiIcon v-if="statusCode" :key="statusCode" :name="`${statusCode}-dark`" class="!h-3 !w-3 text-black-100" />
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showMenu"
        class="absolute right-0 top-12 w-max rounded-xl border-[1.5px] border-solid border-primary-10 bg-white p-1 text-black-100 shadow"
      >
        <div class="flex flex-row items-center gap-2 p-3">
          <img
            v-if="authStore.getUser?.image"
            :src="authStore.getUser?.image"
            class="size-6 rounded-full object-cover"
          />
          <UiIcon v-else name="user"></UiIcon>
          {{ authStore.getUser?.email }}
        </div>
        <div class="flex flex-col gap-3 border-y border-solid border-black-10 py-2 pl-4 pr-7">
          <div
            v-for="status in statuses"
            :key="status.id"
            class="flex cursor-pointer flex-row items-center gap-4"
            :class="{ hidden: status.code === STATUSES.OFFLINE }"
            @click="setStatus(status)"
          >
            <UiIcon :name="`${status.code}`" class="!h-3 !w-3" />
            <div class="flex flex-col">
              <span class="text-subhead-4">
                {{ status.name }}
              </span>
              <span class="text-caption-2 text-black-70">
                {{ status.description }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-for="option in options"
          :key="option.name"
          class="flex cursor-pointer flex-row items-center gap-2 p-3"
          @click="performAction(option)"
        >
          <UiIcon :name="option.icon"></UiIcon>
          {{ option.name }}
        </div>
        <div class="border-t border-solid border-black-10 p-3 text-black-60">
          <span v-if="mainStore.apiVersion">API v{{ mainStore.apiVersion }} | </span>
          Client v{{ version }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { version } from '@/package.json'

import { useUiStore } from '~/store/ui'
import { useMainStore } from '~/store/index'

import { useAuthStore } from '@/store/auth'
import type { Status } from '@/types'
import { STATUSES } from '~/constants'

const uiStore = useUiStore()
const authStore = useAuthStore()
const mainStore = useMainStore()
const statuses = ref<Status[]>([])
const showMenu = ref(false)
const avatar = ref()

onNuxtReady(() => Promise.all([getStatuses()]))

const statusCode = computed(() => statuses.value.find((s) => s.id === authStore.getUser.status)?.code)

const getStatuses = async () => {
  const response = await useUserStatuses()
  statuses.value = response.filter((s) => s.code !== STATUSES.INACTIVE)
}

const setStatus = async (status: Status) => {
  showMenu.value = false
  if (status.id === authStore.getUser.status) return

  try {
    authStore.setUserStatus(status.id)
    await useSetUserStatus(Number(authStore.getUser.id), status.id)
    uiStore.showSnackBanner()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

onClickOutside(avatar, () => {
  if (showMenu.value) showMenu.value = false
})

type Option = {
  icon: string
  name: string
  path?: string
  action?: Function
}

const options: Option[] = [
  {
    icon: 'settings',
    name: 'Settings',
    path: '/settings',
  },
  {
    icon: 'log-out',
    name: 'Logout',
    action: useLogout,
  },
]

const performAction = (option: Option) => {
  if (option.path) navigateTo(option.path)
  else if (option.action) option.action()
  showMenu.value = false
}
</script>

<style scoped></style>
