import { addMinutes, subMinutes } from 'date-fns'
import { PAYMENT_TOKEN_CODES, PAYMENT_TOKEN_STATUS_CODES } from '~/constants'
import type { ExclusiveUnit, UnitPaymentToken } from '~/types/properties'

export const exclusiveTableMockData = [
  {
    id: 1,
    address: '',
    property_id: 1,
    project_id: 1,
    property_name: 'Project name',
    sales_offer_templates: [
      {
        id: 13,
        name: '',
        property_id: 2221,
        created_at: '2024-12-09T11:20:59.000000Z',
        updated_at: '2025-02-18T13:54:31.000000Z',
      },
    ],
    payment_plans: [],
    developer_name: 'Developer name',
    emirate_name: 'Dubai',
    locations: [],
    sale_status: {
      id: 23,
      name: 'On Sale',
      code: 'on_sale',
    },
    progress_percent: '35.00',
    planned_completion_at: '2025-03-23 00:00:00',
    type: {
      id: 1,
      name: 'Apartment',
      code: 'apartment',
    },
    status: {
      id: 1,
      name: 'Available',
      code: 'available',
    },
    bedroom_type: {
      id: 2,
      note: '1 BR',
    },
    unit_number: '123',
    unit_price: {
      base: {
        value: '500000.0000',
        currency: 'AED',
      },
    },
    total_area_ft2: '40,98',
    living_area_ft2: '40,98',
    balcony_area_ft2: null,
    price_per_ft2: '5000',
    floor_number: 11,
    is_shown: true,
  },
  {
    id: 2,
    property_id: 2221,
    project_id: 900064,
    property_name: 'Project name',
    sales_offer_templates: [
      {
        id: 13,
        name: '',
        property_id: 2221,
        created_at: '2024-12-09T11:20:59.000000Z',
        updated_at: '2025-02-18T13:54:31.000000Z',
      },
    ],
    payment_plans: [],
    developer_name: 'Developer name',
    emirate_name: 'Dubai',
    locations: [],
    sale_status: {
      id: 23,
      name: 'On Sale',
      code: 'on_sale',
    },
    progress_percent: '35.00',
    planned_completion_at: '2025-03-23 00:00:00',
    type: {
      id: 2,
      name: 'Duplex',
      code: 'duplex',
      settingsName: null,
    },
    status: {
      id: 3,
      name: 'On Hold',
      code: 'hold',
      settingsName: null,
    },
    bedroom_type: {
      id: 2,
      note: '1 BR',
    },
    unit_number: '123',
    unit_price: {
      base: {
        value: '600000.0000',
        currency: 'AED',
      },
    },
    total_area_ft2: '40,98',
    living_area_ft2: '40,98',
    balcony_area_ft2: null,
    price_per_ft2: 3000,
    floor_number: 12,
    is_shown: true,
    payment_token: {
      id: 110,
      status: {
        id: 1,
        name: 'Payment pending',
        colorCode: null,
        code: 'payment_pending',
        description: null,
      },
      type: {
        id: 2,
        name: 'Other payment method',
        colorCode: null,
        code: 'manual',
        description: null,
      },
      expires_at: addMinutes(new Date(), 60).toISOString(),
      created_at: subMinutes(new Date(), 30).toISOString(),
      creator: {
        id: 999999,
        name: 'Agent name',
      },
      creator_id: 1,
      payment_method: null,
      amount: '5000.0000',
      currency: {
        id: 234,
        country_id: 234,
        name: 'United Arab Emirates Dirham',
        code: 'AED',
        precision: 2,
        symbol: 'AED',
      },
      lead: {
        id: 31072,
        name: 'Lead Name',
        phone: '314235123',
      },
      lead_id: 31072,
      unit_id: 401,
      unit: null,
      project_id: 1545,
      files: [],
      confirmed_at: null,
      user_confirmed: null,
    },
  },
] as ExclusiveUnit[]

export const leadPaymentTokensMockData = [
  {
    id: 2,
    status: {
      id: 1,
      name: 'Payment pending',
      code: 'payment_pending',
    },
    type: {
      id: 1,
      name: 'Payment link',
      code: 'link',
    },
    expires_at: addMinutes(new Date(), 30).toISOString(),
    created_at: new Date().toISOString(),
    creator: {
      id: 1,
      name: 'Agent name',
    },
    amount: '5000.0000',
    currency_id: 234,
    currency: {
      id: 234,
      country_id: 234,
      name: 'United Arab Emirates Dirham',
      code: 'AED',
      precision: 2,
      symbol: 'AED',
    },
    lead: {
      id: 2,
      name: 'PayTabsLead',
    },
    unit: {
      id: 401,
      property_name: 'Burj Binghatti-Jacob & Co Residences',
      developer_name: 'The Riviera Group',
      unit_number: '1234',
      unit_price: '5000',
      property_image: {
        cdn_url: import.meta.client ? `${window.location.origin}/project.png` : '',
      },
    },
    files: [],
    confirmed_at: '',
  },
  {
    id: 1,
    status: {
      id: 1,
      name: 'Verification pending',
      code: PAYMENT_TOKEN_STATUS_CODES.VERIFICATION_PENDING,
    },
    type: {
      id: 1,
      name: 'Other payment method',
      code: PAYMENT_TOKEN_CODES.MANUAL,
    },
    expires_at: addMinutes(new Date(), 30).toISOString(),
    created_at: new Date().toISOString(),
    creator: {
      id: 1,
      name: 'Agent name',
    },
    amount: '5000.0000',
    currency: {
      id: 234,
      country_id: 234,
      name: 'United Arab Emirates Dirham',
      code: 'AED',
      precision: 2,
      symbol: 'AED',
    },
    lead: {
      id: 2,
      name: 'PayTabsLead',
    },
    unit: {
      id: 401,
      property_name: 'Burj Binghatti-Jacob & Co Residences',
      developer_name: 'The Riviera Group',
      unit_number: '1234',
      unit_price: '5000',
      property_image: {
        cdn_url: import.meta.client ? `${window.location.origin}/project.png` : '',
      },
    },
    files: [
      {
        id: 137,
        name: 'filename.pdf',
        path: '',
        visibility: 1,
        extension: '',
        mime_type: 'application/pdf',
        size: 162857,
        entity: 'payment_token.payment_confirmation',
        entity_id: 124,
        user_id: 1,
      },
      {
        id: 138,
        name: 'filename.pdf',
        path: '',
        visibility: 1,
        extension: '',
        mime_type: 'application/pdf',
        size: 162857,
        entity: 'payment_token.passport',
        entity_id: 124,
        user_id: 1,
      },
    ],
    confirmed_at: '',
  },

  {
    id: 3,
    status: {
      id: 1,
      name: 'Payment pending',
      code: 'payment_pending',
    },
    type: {
      id: 1,
      name: 'Other payment method',
      code: 'manual',
    },
    expires_at: addMinutes(new Date(), 30).toISOString(),
    created_at: new Date().toISOString(),
    creator: {
      id: 999999,
      name: 'Agent name',
    },
    amount: '5000.0000',
    currency: {
      id: 234,
      country_id: 234,
      name: 'United Arab Emirates Dirham',
      code: 'AED',
      precision: 2,
      symbol: 'AED',
    },
    lead: {
      id: 31072,
      name: 'PayTabsLead',
    },
    unit: {
      id: 401,
      property_name: 'Burj Binghatti-Jacob & Co Residences',
      developer_name: 'The Riviera Group',
      unit_number: '1234',
      unit_price: '5000',
      property_image: {
        cdn_url: import.meta.client ? `${window.location.origin}/project.png` : '',
      },
    },
    files: [],
    confirmed_at: '',
  },
] as Record<keyof UnitPaymentToken, any>[]
