<template>
  <div class="flex flex-col gap-2">
    <p>
      Lead data has been enriched or fixed through the application of the enrichment rule
      <NuxtLink :to="`/rotations/data-enrichment?query=${modelValue.data?.lead_enrichment_name}`">
        <b>"{{ modelValue.data?.lead_enrichment_name }}".</b>
      </NuxtLink>
    </p>
    <div class="flex flex-col gap-1">
      <span class="text-caption-2 text-black-40">Value:</span>
      <span v-for="info in originalItems" :key="info.field" class="flex flex-col gap-2">
        <div class="flex flex-row items-center gap-1">
          <span>{{ info.field }} updated from</span>
          <span class="text-subhead-3">{{ Number(info.value) === -1 ? 'Unknown' : info.value }}</span
          >to
          <span class="text-subhead-3">{{
            newItems.find((item) => item.field === info.field)?.value || 'Unknown'
          }}</span>
        </div>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '~/types'

const modelValue = defineModel<TimelineItem>({ required: true })

const originalItems = modelValue.value.additional.old_data?.filter((item) => item.value)
const newItems = modelValue.value.additional.new_data?.filter((item) => item.value)
</script>

<style scoped></style>
