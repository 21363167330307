<template>
  <div ref="root" :style="rootStyle" class="styled-scrollbar-near">
    <div ref="spacer" :style="spacerStyle">
      <div v-for="item in visibleItems" :key="item.id">
        <slot :item="item"></slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { InputItem } from '@/types'

type Props = {
  visibleItemsList: InputItem[]
}
const props = defineProps<Props>()
const root = ref<HTMLElement | null>(null)
const spacer = ref<HTMLElement | null>(null)
const rootHeight = ref<number>(250)
const rowHeight = ref<number>(40)
const scrollTop = ref<number>(0)
const nodePadding = ref<number>(20)
const startIndex = computed(() => {
  let startNode = Math.floor(scrollTop.value! / rowHeight.value) - nodePadding.value
  startNode = Math.max(0, startNode)
  return startNode
})
const visibleNodeCount = computed(() => {
  let count = Math.ceil(rootHeight.value / rowHeight.value) + 2 * nodePadding.value
  count = Math.min(itemCount.value - startIndex.value, count)
  return count
})
const visibleItems = computed(() => {
  return props.visibleItemsList.slice(startIndex.value, startIndex.value + visibleNodeCount.value)
})
const itemCount = computed(() => props.visibleItemsList.length)
const offsetY = computed(() => startIndex.value * rowHeight.value)
const spacerStyle = computed(() => {
  return {
    transform: 'translateY(' + offsetY.value + 'px)',
  }
})
const rootStyle = computed(() => {
  return {
    maxHeight: rootHeight.value + 'px',
    height: 'auto',
    overflow: 'auto',
  }
})
const doesBrowserSupportPassiveScroll = () => {
  let passiveSupported = false
  try {
    const options = {
      get passive() {
        passiveSupported = true
        return false
      },
    }
    window.addEventListener('test', null, options)
    window.removeEventListener('test', null, options)
  } catch (err) {
    passiveSupported = false
  }
  return passiveSupported
}
const handleScroll = () => {
  scrollTop.value = root.value?.scrollTop!
}
const calculateInitialRowHeight = () => {
  const children = spacer.value?.children || []
  let largestHeight = 0
  for (let i = 0; i < children.length; i++) {
    if (children[i].offsetHeight > largestHeight) {
      largestHeight = children[i].offsetHeight
    }
  }
  return largestHeight
}
onMounted(() => {
  root.value?.addEventListener('scroll', handleScroll, doesBrowserSupportPassiveScroll() ? { passive: true } : false)
  const largestHeight = calculateInitialRowHeight()
  rowHeight.value = typeof largestHeight !== 'undefined' && largestHeight !== null ? largestHeight : 30
})
</script>
