import type { ApiResponse } from '~/types'
import type { FetchParameters } from '~/composables/utils/useAPIFetch'
import { useAuthStore } from '~/store/auth'

export const useAPIPropertiesFetch = <T = ApiResponse>(path: FetchParameters[0], options?: FetchParameters[1]) => {
  const config = useRuntimeConfig()
  const authStore = useAuthStore()
  const headers = {
    ...options?.headers,
  }

  if (authStore.getUserCurrencyCode) {
    headers['x-thrive-currency-code'] = authStore.getUserCurrencyCode
  }
  if (authStore.getUserMeasureId) {
    headers['x-thrive-measure-id'] = authStore.getUserMeasureId
  }

  return useAPIFetch<T>(path, {
    baseURL: config.public.API_URL_PROPERTY_CATALOGUE,
    headers,
    ...options,
  })
}
