<template>
  <div v-if="modelValue.additional">
    <template v-if="customComponent">
      <component :is="customComponent" :model-value="modelValue"></component>
    </template>
    <div v-else>
      <div v-if="authorName">{{ authorName }} <span class="text-subhead-3">updated</span> this lead.</div>
      <div>
        <span class="text-caption-2 text-black-40">Updated info:</span>
        <div class="flex flex-wrap gap-x-2 gap-y-1">
          <span v-for="(info, index) in additionalItems" :key="info.field">
            <span class="text-subhead-3">{{ info.field }}</span> -
            <span>{{
              (Number(info.value) === -1 ? 'Unknown' : info.value) + (additionalItems.length - 1 > index ? ';' : '')
            }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Component } from 'nuxt/schema'
import type { TimelineItem } from '@/types'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const customFieldsMap = new Map<string, Component>([
  ['information:lead_created', resolveComponent('UiTimelineItemLeadInformationCreateLead') as Component],
  ['Shadow lead Created', resolveComponent('UiTimelineItemLeadInformationCreateShadowLead') as Component],
  ['Release to pool', resolveComponent('UiTimelineItemLeadInformationPoolLead') as Component],
  ['Duplicate', resolveComponent('UiTimelineItemLeadInformationDuplicateLead') as Component],
  ['Hot lead', resolveComponent('UiTimelineItemLeadInformationHotLead') as Component],
  ['failed_data', resolveComponent('UiTimelineItemLeadInformationFailedLead') as Component],
  ['information:lead_manual_reassign', resolveComponent('UiTimelineItemLeadInformationReassign') as Component],
  ['information:lead_auto_reassign', resolveComponent('UiTimelineItemLeadInformationReassign') as Component],
  ['information:lead_returned_to_queue', resolveComponent('UiTimelineItemLeadInformationQueueLead') as Component],
  ['Meeting Outcome', resolveComponent('UiTimelineItemLeadInformationMeetingOutcome') as Component],
  ['information:widget_lead_created', resolveComponent('UiTimelineItemLeadInformationCreateWidgetLead') as Component],
])

// Information item with custom details
const customComponent = computed(() => {
  let customFieldKey = Array.from(customFieldsMap.keys()).find((k) =>
    props.modelValue.additional?.find((i) => i.field === k)
  )
  if (!customFieldKey && props.modelValue.sub_type)
    customFieldKey = Array.from(customFieldsMap.keys()).find((k) => k === props.modelValue.sub_type)
  if (!customFieldKey) return null
  return customFieldsMap.get(customFieldKey)
})

const additionalItems = props.modelValue.additional?.filter(
  (item) => item.value && !Array.from(customFieldsMap.keys()).includes(item.value)
)
const authorName = props.modelValue.user?.id === authStore.getUser.id ? 'You' : props.modelValue.user?.name
</script>

<style scoped></style>
