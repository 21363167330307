<template>
  <span class="min-h-6 rounded-lg bg-primary-05 px-2 py-1 text-primary-90">
    {{ time }}
  </span>
</template>

<script setup lang="ts">
import { differenceInSeconds, isBefore, isFuture, parseISO } from 'date-fns'

type TimerProps = {
  endDate?: string
  startDate?: string
}

type Props = {
  hideSeconds?: boolean
  suffix?: string
}

const props = defineProps<TimerProps & Props>()

const emit = defineEmits<{
  (e: 'ended'): void
}>()

const DEFAULT_TIME_LEFT = props.hideSeconds ? '00:00' : '00:00:00'

const timer = ref<NodeJS.Timeout | null>(null)
const time = ref(DEFAULT_TIME_LEFT)

const clearTimer = () => {
  time.value = DEFAULT_TIME_LEFT

  timer.value && clearInterval(timer.value)
  timer.value = null
}

type TimerArgs = { startDate: Date | undefined; endDate: Date | undefined }

enum TIMER_MODES {
  COUNTUP = 'countup',
  COUNTDOWN = 'countdown',
}

const getTimerMode = (args: TimerArgs): TIMER_MODES => {
  const { startDate, endDate } = args

  if (startDate && endDate) {
    throw new Error('Please provide either start date or end date')
  }

  if (startDate) {
    return TIMER_MODES.COUNTUP
  }

  if (endDate) {
    return TIMER_MODES.COUNTDOWN
  }

  return TIMER_MODES.COUNTDOWN
}

const getDifference = (args: TimerArgs, mode: TIMER_MODES) => {
  const now = new Date()

  const { startDate, endDate } = args

  const difference =
    mode === TIMER_MODES.COUNTUP ? differenceInSeconds(now, startDate!) : differenceInSeconds(endDate!, now)

  const hours = Math.floor(difference / 3600)
  const minutes = Math.floor((difference - hours * 3600) / 60)
  const seconds = difference - hours * 3600 - minutes * 60

  const hoursPadded = hours.toString().padStart(2, '0')
  const minutesPadded = minutes.toString().padStart(2, '0')
  const secondsPadded = seconds.toString().padStart(2, '0')

  return `${hoursPadded}:${minutesPadded}${props.hideSeconds ? '' : `:${secondsPadded}`} ${
    props.suffix ? props.suffix : ''
  }`
}

const updateTimer = (args: TimerArgs) => {
  const now = new Date()

  const { startDate, endDate } = args

  const mode = getTimerMode({ startDate, endDate })

  if (mode === TIMER_MODES.COUNTDOWN && endDate) {
    if (isFuture(endDate)) {
      time.value = getDifference({ startDate, endDate }, mode)
    } else {
      time.value = DEFAULT_TIME_LEFT

      emit('ended')

      clearTimer()
    }

    return
  }

  if (mode === TIMER_MODES.COUNTUP && startDate) {
    if (isBefore(startDate, now)) {
      time.value = getDifference({ startDate, endDate }, mode)
    }
  }
}

const startTimer = ({ startDate, endDate }: TimerProps) => {
  const start = startDate ? parseISO(startDate) : undefined
  const end = endDate ? parseISO(endDate) : undefined

  timer.value = setInterval(() => updateTimer({ startDate: start, endDate: end }), 1000)

  updateTimer({ startDate: start, endDate: end })
}

onMounted(() => {
  startTimer({
    startDate: props.startDate,
    endDate: props.endDate,
  })
})

onUnmounted(() => {
  clearTimer()
})
</script>

<style scoped></style>
