<template>
  <div
    id="support-panel"
    ref="supportPanel"
    :class="[showCall ? '-z-10' : 'z-30', isOpenPanel ? 'translate-x-0' : 'translate-x-[calc(100%-1.5rem)]']"
    class="fixed bottom-10 right-0 hidden min-h-[201px] w-56 justify-stretch overflow-hidden rounded-l bg-white shadow transition-all duration-300 sm:flex"
  >
    <div
      id="open_support"
      class="flex w-6 cursor-pointer flex-col items-center justify-between bg-black-100 py-2"
      @click="isOpenPanel = !isOpenPanel"
    >
      <UiIcon v-show="isOpenPanel" name="big-close" size="xxs" class="text-white" />
      <UiIcon v-show="!isOpenPanel" name="chevron-big-left" size="xxs" class="text-white" />
      <p class="text-caption-2 -rotate-90 whitespace-nowrap pl-12 text-black-20">Help center</p>
    </div>
    <Transition name="fade" mode="out-in">
      <SupportPanelSection v-if="selectedSection === 'updates'" @back="selectedSection = null">
        <template #title> Product updates </template>
        <SupportPanelUpdate
          v-for="update in supportPanelUpdatesToShow"
          :key="update.id"
          :checked="uiStore.getIsTourCompleted(update.id)"
          class="mb-2"
          @check="update.check"
        >
          <template #title>{{ update.title }} </template>
          <template #date> {{ update.date }}</template>
          <template #description> {{ update.description }}</template>
        </SupportPanelUpdate>
      </SupportPanelSection>

      <SupportPanelSection v-else-if="selectedSection === 'support'" @back="selectedSection = null">
        <template #title> Support team </template>
        <div>
          <div class="mb-3 rounded bg-primary-10 p-2">
            <p class="text-caption mb-1">Working Hours</p>
            <p class="text-caption-2 text-black-80">Monday - Friday</p>
            <p class="text-caption-2 text-black-80">9:00 AM - 6:00 PM (Dubai)</p>
          </div>
          <UiTooltip v-if="isDubaiWorkTime" name="support_call_disabled" position-left>
            <template #activator>
              <div>
                <UiButtonBase id="call_button" class="w-full" :disabled="isDisabledCallButton" @click="call()">
                  <UiIcon name="call" :class="isDisabledCallButton ? 'text-black-40' : 'text-white'" />
                  Call support
                </UiButtonBase>
              </div>
            </template>
            <template v-if="isDisabledCallButton" #content>
              {{
                !authStore.getIsActiveForOutboundCall
                  ? 'Change status to online to make calls.'
                  : 'Your telephony is currently disabled. You can enable it in the profile settings to make calls.'
              }}</template
            >
          </UiTooltip>
          <p v-else class="text-caption-3 !font-medium text-black-60">
            Support is unavailable right now. <br />
            Please contact Support team during working hours.
          </p>
        </div>
      </SupportPanelSection>

      <div v-else class="p-2">
        <SupportPanelItem @select="selectedSection = 'updates'">
          <template #title> Product updates </template>
          <template #description>
            New features <br />
            announcements and tours.
          </template>
          <template #count> {{ numberOfUncheckedTours }} </template>
        </SupportPanelItem>

        <div class="mb-3 border-b-[1.5px] border-solid border-black-10 pb-3" />

        <SupportPanelItem @select="selectedSection = 'support'">
          <template #title> Support team </template>
          <template #description>
            Need assistance? Contact <br />
            our support team for quick help.
          </template>
        </SupportPanelItem>
      </div>
    </Transition>

    <LeadCallOutbound
      v-if="showCall"
      :key="Number(showCall)"
      :lead="support"
      support-panel
      :test-mode="true"
      :full-phone="support.fullPhone"
      @called="callTerminated"
      @terminated="showTryLaterPopup()"
    />
  </div>
</template>

<script setup lang="ts">
import { isWithinInterval, getDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { onClickOutside } from '@vueuse/core'
import { POPUPS, TOURS } from '@/components/dynamic/maps'
import { useAuthStore } from '@/store/auth'
import { useUiStore } from '@/store/ui'
import { TOURS_IDS, PERMISSIONS, SETTING_CODES, FEATURE_FLAGS } from '~/constants'

const authStore = useAuthStore()
const uiStore = useUiStore()

const isOpenPanel = ref(false)
const supportPanel = ref<HTMLElement>()
const showCall = ref(false)
const support = ref({
  name: 'Support Specialist',
  fullPhone: '',
} as any)

const workingHours = ref({
  dubaiTimezone: 'Asia/Dubai',
  workHours: {
    start: { hours: 9, minutes: 0 },
    end: { hours: 18, minutes: 0 },
  },
  workDays: [1, 2, 3, 4, 5], // Monday - Friday
})

const selectedSection = ref<'updates' | 'support' | null>(null)

const supportPanelUpdates = [
  {
    id: TOURS_IDS.PAYMENT_LINK,
    title: 'Payment link',
    date: 'March 14, 2025',
    description: 'You can now generate a payment link for exclusive units and send it directly to your clients. ⚡️',
    condition: () =>
      useHasPermissions([PERMISSIONS.CAN_VIEW_PAYMENT_TOKENS]) && useFeatureFlag(FEATURE_FLAGS.PAYMENT_TOKENS),
    check: () => handlePaymentTokensCheck(),
  },
  {
    id: TOURS_IDS.SDR,
    title: 'SDR flow',
    date: 'May 20, 2024',
    description: 'SDR Queue - speed up processing and qualification of incoming leads! 👍🏻',
    condition: () => useHasPermissions([PERMISSIONS.MANAGE_QUEUE]),
    check: () => handleSdrFlowCheck(),
  },
  {
    id: TOURS_IDS.GLOBAL_SEARCH,
    title: 'Global search',
    date: 'Mar 28, 2024',
    description: 'Now you can search info in all Lead and Deal tabs 👍🏻',
    check: () => handleGlobalSearchCheck(),
  },
  {
    id: TOURS_IDS.CALL_LOG,
    title: 'Call logs',
    date: 'Mar 28, 2024',
    description: 'Review all info about your incoming and outgoing calls in one place 😱',
    check: () => handleCallLogsCheck(),
  },
]

const numberOfUncheckedTours = computed(() => {
  return supportPanelUpdates.filter((update) => !uiStore.getIsTourCompleted(update.id)).length
})

const supportPanelUpdatesToShow = computed(() =>
  supportPanelUpdates
    .filter((update) => (update.condition ? update.condition() : true))
    .sort((a, b) => {
      // sort by user's tours, unchecked tours go first
      return Number(uiStore.getIsTourCompleted(a.id)) - Number(uiStore.getIsTourCompleted(b.id))
    })
)

const isDisabledCallButton = computed(() => {
  return !authStore.getUser.is_voice_call_enabled || !authStore.getIsActiveForOutboundCall
})

onClickOutside(supportPanel, () => {
  if (isOpenPanel.value) {
    selectedSection.value = null
    isOpenPanel.value = false
  }
})

onNuxtReady(async () => {
  try {
    const { settings } = await useGetSettingsByCode(SETTING_CODES.SUPPORT_PHONE)
    support.value.fullPhone = settings[SETTING_CODES.SUPPORT_PHONE]
    uiStore.showTour({
      component: TOURS.WELCOME_ABOARD,
      id: TOURS_IDS.WELCOME_ABOARD,
    })
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
})

const call = () => {
  showCall.value = true
}

const callTerminated = () => {
  showCall.value = false
}

const showTryLaterPopup = () => {
  callTerminated()
  uiStore.showPopup(POPUPS.TRY_CALL_LATER_POPUP)
}

// Check on working hours
const isDubaiWorkTime = computed(() => {
  const { dubaiTimezone, workHours } = workingHours.value

  const now = new Date()
  const nowDubai = utcToZonedTime(now, dubaiTimezone)

  // Check on working day
  if (!isWorkDay(nowDubai)) {
    return false
  }

  const workStartTime = calculateDateTime(nowDubai, workHours.start.hours, workHours.start.minutes)

  const workEndTime = calculateDateTime(nowDubai, workHours.end.hours, workHours.end.minutes)

  return isWithinInterval(nowDubai, { start: workStartTime, end: workEndTime })
})

const calculateDateTime = (baseDate: Date, hours: number, minutes: number) => {
  return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), hours, minutes, 0, 0)
}
const isWorkDay = (date: Date) => {
  const dayOfWeek = getDay(date)
  return workingHours.value.workDays.includes(dayOfWeek)
}

const isSalesAllPageMounted = useState('isSalesAllPageMounted', () => false)

// TODO: Refactor all methods to use setActiveTourId

const handleCallLogsCheck = async () => {
  await navigateTo('/pipelines/sales')

  isOpenPanel.value = false
  selectedSection.value = null

  await until(isSalesAllPageMounted).toBe(true)

  await new Promise((resolve) => setTimeout(resolve, 1000))
  uiStore.showTour({
    component: TOURS.CALL_LOG,
    id: TOURS_IDS.CALL_LOG,
    checkCompleted: false,
  })
}

const handleGlobalSearchCheck = async () => {
  isOpenPanel.value = false
  selectedSection.value = null
  uiStore.showTour({
    component: TOURS.GLOBAL_SEARCH,
    id: TOURS_IDS.GLOBAL_SEARCH,
    checkCompleted: false,
  })
  await nextTick()
  setTimeout(() => {
    useMittEvent('tour:globalSearch')
  }, 100)
}

const isQueueTableLoaded = useState('isQueueTableLoaded', () => false)

const handleSdrFlowCheck = async () => {
  isOpenPanel.value = false
  selectedSection.value = null

  await navigateTo('/pipelines/queue')

  await until(isQueueTableLoaded).toBe(true)

  uiStore.showTour({
    component: TOURS.SDR,
    id: TOURS_IDS.SDR,
    checkCompleted: false,
  })
}

const route = useRoute()

const handlePaymentTokensCheck = async () => {
  isOpenPanel.value = false
  selectedSection.value = null

  uiStore.setActiveTourId(TOURS_IDS.PAYMENT_LINK)

  await nextTick()

  if (route.name !== 'properties-exclusives') {
    await navigateTo({
      name: 'properties-exclusives',
    })
  }
}
</script>
