<template>
  <div ref="notesDiv" class="relative sm:h-full sm:overflow-y-auto" @scroll="checkScroll">
    <div v-if="!isMobile" class="mt-2 pl-2 pr-4">
      <UiInputTextareaNote
        v-model="note"
        name="note"
        :disabled="isNoteDisabled"
        placeholder="Your note..."
        button-text="Save"
        :class="addNoteClasses"
        @input="saveNote"
      />
    </div>

    <div v-if="isMobile" class="flex flex-col gap-2 px-3 pt-3">
      <UiInputTextarea
        v-model="note"
        name="note"
        :disabled="isNoteDisabled"
        placeholder="Your note..."
        :start-rows="2"
      />
      <UiButtonBase id="save-note" type="secondary" :disabled="isNoteDisabled" class="w-full" @click="saveNote"
        >Save
      </UiButtonBase>
    </div>
    <div class="mt-4 flex flex-col gap-2 px-3 pb-4 sm:gap-3 sm:pl-2 sm:pr-4">
      <div class="flex flex-row items-end gap-1">
        <h4 class="text-subhead-4 sm:text-subhead-1">{{ isMobile ? 'History' : 'Notes' }}</h4>
        <h5 v-if="!loading && !isMobile" class="text-subhead-3 text-black-60">({{ totalCount }})</h5>
      </div>
      <UiTimelineBlock v-if="notesItems.length" v-model="notesItems" />
      <UiLoader v-if="loading" class="mb-4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Lead, TimelineItem } from '@/types'
import { useUiStore } from '~/store/ui'

type Props = {
  leadId: number
  lead?: Lead
  addNoteClasses?: string
  readOnlyNoDetails?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  lead: undefined,
  addNoteClasses: '',
})

const uiStore = useUiStore()
const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')

const note = ref('')
const notes = ref<TimelineItem[]>([])
const notesDiv = ref<HTMLElement>()
const creatingNote = ref(false)

const loading = ref(true)
const currentPage = ref(0)
const lastPage = ref(0)
const totalCount = ref(0)

onNuxtReady(() => getActivity())

const getActivity = async () => {
  loading.value = true

  currentPage.value += 1

  try {
    const response = await useGetLeadActivity(props.leadId, 'note', currentPage.value)
    notes.value.push(...(response.data as TimelineItem[]))
    currentPage.value = response.pagination.current_page

    lastPage.value = response.pagination.total_pages
    totalCount.value = response.pagination.total
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}

const notesItems = computed({
  get() {
    return notes.value
  },
  set(value) {
    value.forEach((v) => {
      const item = notes.value.find((a) => a.id === v.id)
      if (item) Object.assign(item, v)
    })
  },
})

const isNoteDisabled = computed(() => creatingNote.value)

const checkScroll = async () => {
  const scrollIsAlmostBottom =
    Number(notesDiv?.value?.scrollTop) + Number(notesDiv?.value?.clientHeight) + 500 >
    Number(notesDiv?.value?.scrollHeight)

  if (scrollIsAlmostBottom && currentPage.value < lastPage.value && !loading.value) {
    await getActivity()
  }
}

const saveNote = async () => {
  if (!note.value) return
  const activityItem = {
    type: 'note',
    comment: note.value,
    lead_id: props.leadId,
  } as unknown as Partial<TimelineItem>
  creatingNote.value = true
  try {
    const { data } = await useCreateLeadActivity(activityItem)
    notes.value.unshift(data)
    totalCount.value++
    note.value = ''
    notesDiv.value?.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    creatingNote.value = false
  }
}
</script>

<style scoped></style>
