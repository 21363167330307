<template>
  <!-- Text Input -->
  <div :id="name" class="flex w-full flex-col items-start space-y-1">
    <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
    <label class="relative flex min-h-10 w-full flex-row items-center justify-between gap-2">
      <textarea
        :id="name"
        :ref="(el) => (refs[name] = el as HTMLTextAreaElement)"
        v-model="vModel"
        :name="name"
        rows="1"
        :placeholder="placeholder"
        :disabled="disabled"
        class="peer z-10 my-2 ml-4 flex-1 resize-none overflow-y-hidden border-none bg-transparent text-sm font-normal outline-none transition-all duration-200 placeholder:text-sm placeholder:font-normal placeholder:leading-5"
        @input="resizeTextArea"
      />
      <div v-if="showSaveButton" class="flex self-end">
        <UiButtonBase :id="`button_${name}`" :disabled="disabled" class="bottom-0 right-0 z-20" @click="$emit('input')">
          {{ buttonText }}
        </UiButtonBase>
      </div>
      <div
        class="absolute left-0 z-0 size-full rounded-xl border-[1.5px] border-solid border-black-20 outline-none transition-colors duration-200 hover:border-primary-50 active:border-primary-120 peer-hover:border-primary-50 peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05"
      ></div>
    </label>
    <p v-if="showCount" class="text-caption-2 mx-4 text-black-60">{{ modelValue.length }}/100</p>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: string
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  description?: string
  buttonText?: string
  showCount?: boolean
  isResizing?: boolean
  showSaveButton?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  label: '',
  placeholder: '',
  description: '',
  buttonText: 'Save',
  showSaveButton: true,
})

watch(
  () => props.modelValue,
  (value) => {
    if (!value) {
      setTimeout(() => {
        const textarea = refs[props.name]

        if (!textarea) return

        textarea.style.height = '20px'
      })
    }
  }
)

const vModel = useVModel(props, 'modelValue', emits)

const refs = {
  [props.name]: null,
} as { [key: string]: HTMLTextAreaElement | null }

const resizeTextArea = () => {
  const textarea = refs[props.name]

  if (!textarea) return

  textarea.style.height = '1px'
  textarea.style.height = textarea.scrollHeight + 'px'
}

onMounted(() => {
  if (props.isResizing) resizeTextArea()
})
</script>

<style scoped></style>
