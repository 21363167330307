<template>
  <div class="flex flex-col gap-2">
    <div class="text-caption flex items-center gap-1 text-black-90">
      <UiTimer
        :end-date="paymentToken.expires_at"
        :class="{
          '!bg-additional-3-10 !text-additional-3-100': isVerificationPendingStatus,
        }"
        @ended="emits('ended', paymentToken.id)"
      />
      <span>{{ $t('on_hold') }}</span>

      <span>•</span>

      <span>{{ paymentToken.type.name }}</span>

      <div
        v-if="closable"
        class="ml-auto cursor-pointer rounded-lg transition-colors hover:bg-primary-05"
        @click="emits('close')"
      >
        <UiIcon name="small-close" class="text-primary-100" />
      </div>
    </div>
    <I18n-t v-if="isPaymentPendingStatus" keypath="reservation_lead_description" tag="div" class="text-body-2">
      <template #unit>
        <span class="text-subhead-4">{{ paymentToken.unit.unit_number }}</span>
      </template>
    </I18n-t>
    <I18n-t
      v-else-if="isVerificationPendingStatus"
      keypath="payment_verification_lead_description"
      tag="div"
      class="text-body-2"
    >
      <template #unit>
        <span class="text-subhead-4">{{ paymentToken.unit.unit_number }}</span>
      </template>
    </I18n-t>
    <UiButtonBase
      v-if="isManualPaymentPendingToken"
      id="add_confirmation"
      size="small"
      @click="emits('add-confirmation', paymentToken.id)"
    >
      {{ $t('add_confirmation') }}
    </UiButtonBase>
  </div>
</template>

<script setup lang="ts">
import type { UnitPaymentToken } from '~/types/properties'
import { PAYMENT_TOKEN_CODES, PAYMENT_TOKEN_STATUS_CODES } from '~/constants'

const props = defineProps<{
  paymentToken: UnitPaymentToken
  closable?: boolean
}>()

const emits = defineEmits<{
  (e: 'close'): void
  (e: 'ended', id: UnitPaymentToken['id']): void
  (e: 'add-confirmation', id: UnitPaymentToken['id']): void
}>()

const isManualPaymentPendingToken = computed(() => {
  return props.paymentToken.type.code === PAYMENT_TOKEN_CODES.MANUAL && isPaymentPendingStatus.value
})

const isVerificationPendingStatus = computed(() => {
  return props.paymentToken.status.code === PAYMENT_TOKEN_STATUS_CODES.VERIFICATION_PENDING
})

const isPaymentPendingStatus = computed(() => {
  return props.paymentToken.status.code === PAYMENT_TOKEN_STATUS_CODES.PAYMENT_PENDING
})
</script>

<style scoped></style>
