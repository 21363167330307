<template>
  <div class="flex flex-row items-center gap-2">
    <transition name="fade">
      <UiTagPrimary
        v-if="vModel.status?.code === CALENDAR_ACTIVITY_STATUSES.OVERDUE"
        id="overdue"
        right-icon=""
        compact
        class="!cursor-default"
        background-color-class="bg-secondary-20"
      >
        <span class="text-subhead-3 text-error-100"> Overdue </span>
      </UiTagPrimary>
    </transition>
    <UiTagPrimary
      id="type"
      right-icon=""
      compact
      class="!cursor-default"
      :style="{
        backgroundColor: CALENDAR_ACTIVITY_TYPES_COLORS_MAP.get(vModel.type?.code)?.background,
      }"
    >
      <span class="text-caption text-black-100">
        {{ vModel.type.name }}
      </span>
    </UiTagPrimary>
    <UiInputCheckbox
      v-if="!readOnlyNoDetails"
      :model-value="isDone"
      :name="vModel.title"
      round
      border-color-class="border-black-30"
      :read-only="
        isDone ||
        (vModel.user?.id !== authStore.getUser.id &&
          !useHasPermissions([PERMISSIONS.CLOSE_ACTIVITIES_ON_BEHALF_OF_THE_USER]))
      "
      @click.stop
      @update:model-value="updateActivityStatus"
    />
    <span class="text-subhead-4 text-black-100">{{ vModel.title }}</span>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'
import { POPUPS, SIDE_PANELS } from '@/components/dynamic/maps'
import type { LibraryItem, TimelineItem, Activity } from '@/types'
import { useAuthStore } from '~/store/auth'
import {
  CALENDAR_ACTIVITY_TYPES,
  CALENDAR_ACTIVITY_STATUSES,
  CALENDAR_ACTIVITY_TYPES_COLORS_MAP,
  CUSTOM_EVENTS,
  PERMISSIONS,
} from '@/constants'

const emits = defineEmits(['update:modelValue'])

const authStore = useAuthStore()
const uiStore = useUiStore()

const readOnlyNoDetails = computed(() => authStore.getPermissions.includes(PERMISSIONS.SEE_TIMELINE_NO_DETAILS))

type Props = {
  modelValue: TimelineItem & Activity
}
const props = defineProps<Props>()

const vModel = useVModel(props, 'modelValue', emits)
const activity = ref()
const loading = ref(false)

const lead = inject<Ref<boolean>>('lead')

const eventStatuses = ref<LibraryItem[]>([])
onNuxtReady(async () => {
  eventStatuses.value = await useCalendarActivityStatuses()
})

const isDone = computed(() => vModel.value.status?.code === CALENDAR_ACTIVITY_STATUSES.DONE)

const updateActivityStatus = async () => {
  if (vModel.value.type?.code === CALENDAR_ACTIVITY_TYPES.MEETING && !vModel.value.meeting_outcome_resolution_id) {
    uiStore.showPopup(
      POPUPS.MEETING_OUTCOME,
      { activity: { ...vModel.value, start: vModel.value.schedule_from, end: vModel.value.schedule_to, lead } },
      { input: () => refreshHistory() }
    )
    return
  }
  const statusCode = CALENDAR_ACTIVITY_STATUSES.DONE

  const status = eventStatuses.value.find((s) => s.code === statusCode)

  if (!status) return
  try {
    await useUpdateCalendarActivityStatus(Number(vModel.value.id), status.id)

    vModel.value.status = status
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getActivity = async () => {
  loading.value = true
  if (!activity.value) {
    try {
      activity.value = await useGetCalendarActivity(vModel.value.id)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    }
  }
  loading.value = false
  setTimeout(() => {
    uiStore.showSidePanel(
      SIDE_PANELS.ACTIVITY_PREVIEW,
      { activity },
      {
        edit: () => showEditEventSidePanel(),
        delete: () => showDeleteActivityPopup(),
        'update:modelValue': () => refreshHistory(),
        'update:status': (status: LibraryItem) => (activity.value.status = status),
      }
    )
  }, 200)
}

const showEditEventSidePanel = () => {
  if (vModel.value.status?.code === CALENDAR_ACTIVITY_STATUSES.DONE) return
  uiStore.cleanSidePanel()
  setTimeout(() => {
    uiStore.showSidePanel(
      SIDE_PANELS.ACTIVITY_CREATE_EDIT,
      { activityToEdit: activity },
      {
        edited: (updatedActivity: Activity) => {
          activity.value = updatedActivity
          refreshHistory()
        },
      }
    )
  }, 200)
}

const showDeleteActivityPopup = () => {
  uiStore.showPopup(
    POPUPS.ACTIVITY_DELETE,
    { activity },
    { cancel: () => getActivity(), input: () => refreshHistory() }
  )
}

const refreshHistory = () => {
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))
}
</script>

<style scoped></style>
