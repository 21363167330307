<template>
  <div class="flex flex-row justify-between">
    <div class="w-full pt-2 [overflow-wrap:anywhere]">
      <span class="whitespace-pre-wrap bg-additional-1-30">{{ modelValue.comment }}</span>
      <UiInputTextareaNote
        v-if="editMode"
        v-model="noteText"
        :name="`${modelValue.id}_edit`"
        :disabled="updatingNote"
        placeholder="Your note..."
        button-text="Update"
        class="mt-3 w-full"
        :start-rows="isMobile ? 2 : 1"
        :is-resizing="true"
        @input="updateNote"
      />
    </div>
    <div class="absolute right-3 top-4 flex h-fit flex-row gap-1 md:right-11">
      <span v-if="edited" class="text-caption pt-1 text-black-60">edited</span>
      <div v-if="canEdit" class="cursor-pointer rounded-md hover:bg-primary-10" @click="editMode = true">
        <UiIcon name="edit" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { subHours, isAfter } from 'date-fns'
import { useUiStore } from '~/store/ui'
import type { TimelineItem } from '@/types'
import { useAuthStore } from '~/store/auth'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')

const authStore = useAuthStore()
const uiStore = useUiStore()

const editMode = ref(false)
const edited = ref(props.modelValue.updated_at && props.modelValue.created_at !== props.modelValue.updated_at)
const updatingNote = ref(false)
const canEdit =
  isAfter(new Date(props.modelValue.created_at), subHours(new Date(), 24)) &&
  props.modelValue.user.id === authStore.getUser.id

const vModel = defineModel<TimelineItem>('modelValue', {
  required: true,
})
const noteText = ref(vModel.value.comment)

const updateNote = async () => {
  updatingNote.value = true
  try {
    if (props.modelValue.comment !== noteText.value) {
      await useUpdateLeadActivity(props.modelValue.id, { comment: noteText.value })
    }
    vModel.value = { ...vModel.value, comment: noteText.value }
    noteText.value = vModel.value.comment
    editMode.value = false
    edited.value = true
    uiStore.showSnackBanner()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    updatingNote.value = false
  }
}

watch(
  () => props.modelValue.comment,
  () => {
    noteText.value = props.modelValue.comment
  }
)
</script>

<style scoped></style>
