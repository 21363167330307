<template>
  <label class="relative flex size-full h-10 flex-row items-center gap-2 px-4 py-2 xs:py-3">
    <client-only>
      <UiIcon
        :name="icon ? icon : 'search'"
        :class="[iconClasses, isInputFocused ? 'text-primary-120' : vModel ? 'text-black-100' : 'text-black-60']"
        class="peer/icon-prefix z-10 min-w-6"
      ></UiIcon>
    </client-only>
    <input
      id="search"
      v-model="vModel"
      name="search-field"
      type="text"
      :placeholder="placeholder || $t('search')"
      autocomplete="off"
      class="text-body-2 peer z-10 h-[20px] max-w-[calc(100%-48px)] flex-1 border-none bg-transparent outline-none transition-width duration-200 ease-linear placeholder:text-sm placeholder:font-normal placeholder:leading-5"
      @focus="isInputFocused = true"
      @blur="isInputFocused = false"
      @keydown.enter="emits('search', lowercase ? vModel?.toLowerCase() : vModel)"
      @input="instantSearch"
    />
    <transition name="fade">
      <UiIcon
        v-show="vModel"
        name="big-close"
        :class="[isInputFocused ? 'text-primary-120' : vModel ? 'text-black-100' : '', iconClasses]"
        class="peer/icon z-10 min-w-6 cursor-pointer"
        @click.prevent.stop="clearSearch"
      />
    </transition>
    <div
      class="absolute left-0 z-0 size-full rounded-xl border-[1.5px] border-solid border-black-20 bg-white outline-none hover:border-primary-50 active:border-primary-120 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50 peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05"
      :class="inputClasses"
    ></div>
  </label>
</template>

<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import { DEFAULT_DEBOUNCE_INPUT_TIME } from '@/constants'
const emits = defineEmits(['update:modelValue', 'search', 'search:debounced'])

type Props = {
  modelValue: string | undefined | null
  instant?: boolean
  icon?: string
  iconClasses?: string
  inputClasses?: string
  lowercase?: boolean
  placeholder?: string | undefined
}
const props = withDefaults(defineProps<Props>(), {
  lowercase: true,
  icon: '',
  iconClasses: '',
  inputClasses: '',
  placeholder: undefined,
})

const vModel = useVModel(props, 'modelValue', emits)

const isInputFocused = ref(false)

const instantSearch = () => {
  if (props.instant) {
    emits('search', vModel.value?.toLowerCase())
  }
}

const clearSearch = () => {
  vModel.value = ''
  emits('search', '')
}

watchDebounced(
  vModel,
  () => {
    emits(
      'search:debounced',
      props.lowercase && typeof vModel.value === 'string' ? vModel.value?.toLowerCase() : vModel.value
    )
  },
  { debounce: DEFAULT_DEBOUNCE_INPUT_TIME }
)
</script>

<style scoped></style>
