<template>
  <Teleport to="body">
    <div
      v-if="!isIpPhone && isCallStarted"
      class="fixed inset-x-0 bottom-12 z-20 mx-auto flex h-auto w-full flex-row items-center justify-between rounded-2xl bg-black-90 p-4 text-white shadow sm:w-8/12 lg:w-[666px]"
    >
      <audio ref="remoteAudio"></audio>
      <div class="flex flex-col">
        <span v-if="lead.name" class="text-subhead-1">{{ lead.name }}</span>
        <span v-if="useGetShowPhoneLead(lead)" class="text-body-2">+{{ currentNumber }}</span>
      </div>
      <div class="flex flex-row items-center gap-10">
        <div class="flex flex-row items-center gap-4">
          <span class="text-body-2">{{ parsedTime }}</span>
          <button
            class="flex size-10 items-center justify-center rounded-full bg-error-100 active:bg-error-80"
            @click="terminate"
          >
            <UiIcon name="call-off" class="text-white"></UiIcon>
          </button>
        </div>
        <div class="flex flex-row items-center gap-4">
          <button
            class="flex size-10 items-center justify-center rounded-full bg-white/20 active:bg-white/40"
            :class="{ 'bg-white/40': sessionIsMuted }"
            @click="toggleMute"
          >
            <UiIcon name="micro" class="text-white"></UiIcon>
          </button>
          <button class="relative flex size-10 items-center justify-center rounded-full bg-white/20 active:bg-white/40">
            <transition name="fade">
              <LeadCallAudioDevices v-model="showDevices" />
            </transition>
            <UiIcon name="settings" class="text-white" @click="showDevices = !showDevices"></UiIcon>
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { Device } from '@twilio/voice-sdk'

import { useIOStore } from '~/store/io'
import { useUiStore } from '~/store/ui'
import { useAuthStore } from '@/store/auth'
import { useLibrariesStore } from '@/store/libraries'
import type { Lead } from '@/types'
import { STATUSES } from '~/constants'

const ioStore = useIOStore()
const uiStore = useUiStore()
const authStore = useAuthStore()
const librariesStore = useLibrariesStore()

const CODES_TOKEN_INVALID = [20101, 20104]

type Props = {
  lead: Lead
  localNumber?: string
  testMode?: boolean
  fullPhone?: string
  callWay?: string
  supportPanel?: boolean
  needDefineNumberFrom?: boolean
}

const emits = defineEmits<{
  (e: 'terminated', refresh: boolean): void
  (e: 'called'): void
  (e: 'no-answer'): void
}>()

const props = withDefaults(defineProps<Props>(), {
  callWay: 'crm',
  supportPanel: false,
  fullPhone: '',
  localNumber: '',
})

let device: Device | null = null
let currentConnection: any | null = null

const callId = ref('')
const twilioToken = ref('')
const isCallStarted = ref(false)

const remoteAudio = ref()
const duration = ref(0)
let timer: NodeJS.Timer
const sessionIsMuted = ref(false)
const showDevices = ref(false)

const currentUserStatus = useCookie('userStatus')
const currentNumber = ref(props.fullPhone || `${props.lead.phone_country.phone_code}${props.lead.phone}`)
const fromNumber = ref(props.localNumber)
const configEnv = useRuntimeConfig()
// Slice method delete '/' in the end
const domain = configEnv.public.APP_URL.split('.')[1]?.slice(0, -1) || 'app'

const isIpPhone = computed(() => props.callWay === 'phone')

const callStatusData = [
  {
    codes: [486, 600, 31486],
    message: 'The lead is busy',
    sound: `https://cdn.thrivestate.${domain}/sounds/busy.mp3`,
  },
  {
    codes: [403, 603, 31008, 13225],
    message: 'The call was rejected',
    sound: `https://cdn.thrivestate.${domain}/sounds/rejected.mp3`,
  },
  {
    codes: [300, 301, 302, 305, 380, 31000],
    message: 'The call was redirected',
    sound: `https://cdn.thrivestate.${domain}/sounds/redirected.mp3`,
  },
  {
    codes: [480, 410, 408, 430, 487, 31480, 13223],
    message: 'The lead is unavailable',
    sound: `https://cdn.thrivestate.${domain}/sounds/unavailable.mp3`,
  },
  {
    codes: [404, 604, 31001],
    message: 'The call was not found',
    sound: `https://cdn.thrivestate.${domain}/sounds/notFound.mp3`,
  },
  {
    codes: [484, 31484],
    message: 'The call address is incomplete',
    sound: `https://cdn.thrivestate.${domain}/sounds/incompatible.mp3`,
  },
  {
    codes: [488, 606, 32102],
    message: 'The call SDP is incompatible',
    sound: `https://cdn.thrivestate.${domain}/sounds/incomplete.mp3`,
  },
  {
    codes: [401, 407, 32106, 31102, 31205],
    message: 'Authentication error',
    sound: `https://cdn.thrivestate.${domain}/sounds/authenticationError.mp3`,
  },
  {
    codes: [],
    message: 'Call error',
    sound: `https://cdn.thrivestate.${domain}/sounds/error.mp3`,
  },
]

const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')

const handleCallEnd = () => {
  const isCallSuccessful = duration.value > 0

  if (isCallSuccessful) {
    emits('called')
  }

  // Update lead history only in case that connection was terminated before call or ended by error
  emits('terminated', !isCallSuccessful)

  destroyDevice()
}

const failed = async (code: number) => {
  const [, , , CALL_UNAVAILABLE] = callStatusData
  const CALL_ERROR = callStatusData.at(-1)!

  await getBackStatus()

  let errorMessage = CALL_ERROR

  destroyDevice()

  if (isIpPhone.value || code) {
    if (!props.testMode) {
      if (CALL_UNAVAILABLE.codes.includes(code)) {
        return emits('no-answer')
      }
    }

    errorMessage = callStatusData.find((error) => error.codes.includes(code)) || CALL_ERROR!
  }

  emits('terminated', true)

  nextTick(() => {
    uiStore.showSnackBanner(`Error : ${errorMessage.message}`, 'error')
    if (!isMobile.value) new Audio(errorMessage.sound).play()
  })
}

const ended = async () => {
  await getBackStatus()

  handleCallEnd()
}

const getHeaderCall = async () => {
  const payload = {
    lead_id: props.lead.id,
    call_way: props.callWay,
    did: fromNumber.value || null,
    lead_phone: '+' + currentNumber.value,
  }
  if (props.needDefineNumberFrom) {
    payload.need_define_number_from = true
  }
  try {
    const headers = await useVoiceCallStarted(payload)
    callId.value = headers['X-CRM-Call-ID']
    if (props.needDefineNumberFrom) {
      fromNumber.value = headers.need_call_from
    }

    if (isIpPhone.value) {
      subscribeToIpCallChannel(authStore.getUser?.id as number)
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getTwilioToken = async (force: boolean = false) => {
  try {
    const response = await useGetCallsTwilioToken(force)
    twilioToken.value = response.token
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

onNuxtReady(async () => {
  console.log('twilio call', props.lead.id, props.callWay, authStore.getUser?.id)
  try {
    if (!props.testMode) {
      await getHeaderCall()
    }
    setStatusDontDisturb()
    if (isIpPhone.value) return
    destroyDevice()

    await getTwilioToken()

    device = new Device(twilioToken.value, {
      codecPreferences: ['opus', 'pcmu'],
      fakeLocalDTMF: true,
      enableRingingState: true,
      debug: true,
      enableImprovedSignalingErrorPrecision: true,
    })

    // device.on('error', async (deviceError: { code: number }) => {
    //   if (deviceError && CODES_TOKEN_INVALID.includes(deviceError.code)) {
    //     await getTwilioToken(true)
    //     device?.updateToken(twilioToken.value)
    //     startCall()
    //   }
    // })

    startCall()
  } catch (error) {
    console.log(error)
  }
})

const startCall = async () => {
  if (!device || !twilioToken.value) return

  const connectOptions: Device.ConnectOptions = {
    params: {
      to: `+${currentNumber.value}`,
      callId: callId.value,
      leadId: props.lead.id,
    },
  }
  if (fromNumber.value) {
    connectOptions.params!.from = fromNumber.value
  }
  currentConnection = await device.connect(connectOptions)
  isCallStarted.value = true

  currentConnection.on('accept', () => {
    timer = setInterval(() => {
      duration.value += 1
    }, 1000)
  })
  currentConnection.on('disconnect', () => {
    ended()
  })
  currentConnection.on('error', (error: { code: number }) => {
    failed(error.code)
  })
}

const toggleMute = () => {
  if (currentConnection) {
    sessionIsMuted.value = !sessionIsMuted.value
    currentConnection.mute(sessionIsMuted.value)
  }
}

const terminate = () => {
  if (currentConnection) {
    currentConnection.disconnect()
    currentConnection = null
  }

  handleCallEnd()
}

const setStatusDontDisturb = async () => {
  const dontDisturbStatus = librariesStore.getStatuses.find((s) => s.code === STATUSES.DONT_DISTURB)?.id
  if (authStore.getUser.status === dontDisturbStatus) return

  currentUserStatus.value = authStore.getUser.status?.toString()
  await setStatus(dontDisturbStatus)
}

const getBackStatus = async () => {
  if (currentUserStatus.value) {
    await setStatus(Number(currentUserStatus.value))
    currentUserStatus.value = null
  }
}

const setStatus = async (statusId?: number) => {
  authStore.setUserStatus(statusId!)
  await useSetUserStatus(Number(authStore.getUser.id), statusId!)
}

const parsedTime = computed(() => {
  if (duration.value === 0) return 'Calling...'
  const second = duration.value % 60
  const minute = Math.floor(duration.value / 60) % 60
  return `${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`
})

const showIpPhoneCallStatus = (e: any) => {
  const { duration: resDuration, outcome_code: outcomeCode } = e
  unSubscribeToIpCallChannel(authStore.getUser?.id as number)
  const code = Number(outcomeCode)
  if (code === 200) {
    duration.value = resDuration
    ended()
  } else {
    failed(code)
  }
}

onUnmounted(() => {
  clearInterval(timer)
})

const destroyDevice = () => {
  if (device) device.destroy()
  isCallStarted.value = false
}

watch(
  () => ioStore.getHasActivatedPhoneCall,
  (value: any) => {
    if (isIpPhone.value && value) {
      showIpPhoneCallStatus(value.data)
    }
  }
)
</script>

<style scoped></style>
