type ValueParam = Parameters<Intl.NumberFormat['format']>['0']

export default (value: ValueParam, options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat('en-AE', {
    style: 'currency',
    currency: 'AED',
    notation: 'compact',
    maximumFractionDigits: 3,
    trailingZeroDisplay: 'stripIfInteger',
    ...options,
  }).format(value)
}
