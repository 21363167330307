<template>
  <div class="text-body">
    <div>
      <span
        class="mr-1 inline-flex size-8 shrink-0 items-center justify-center rounded-lg bg-additional-1-50 align-sub text-error-100"
      >
        <UiIcon name="info-circle-filled" size="xs" />
      </span>
      <span>
        Referral name <span class="text-subhead-3"> was updated </span> on the Partners Portal from "{{ from }}" to
        <b> "{{ to }}". </b>
      </span>
    </div>
    <div class="mt-3">Check it out and update the Lead if needed.</div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()
const from = props.modelValue.additional?.find((i) => i.field === 'From')?.value
const to = props.modelValue.additional?.find((i) => i.field === 'To')?.value
</script>

<style scoped lang="scss"></style>
