import { defineStore } from 'pinia'
import type Pusher from 'pusher-js'

type MainStoreState = {
  apiVersion: string
  pusher: Pusher | null
}

export const useMainStore = defineStore('main', {
  state: (): MainStoreState => ({
    apiVersion: '',
    pusher: null,
  }),
  actions: {
    setApiVersion(version: string) {
      this.apiVersion = version
    },
    setPusher(pusher: Pusher) {
      this.pusher = pusher
    },
  },
  getters: {
    getAPIVersion: (state) => state.apiVersion,
  },
})
