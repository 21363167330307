<template>
  <div v-if="stagedMoved" class="flex flex-row flex-wrap items-center gap-2">
    <div>
      This lead was <span class="text-subhead-3" :class="{ capitalize: !authorName }">moved</span> to
      <b v-if="pipeline">{{ pipeline }}</b>
    </div>
    <div
      class="flex h-6 w-fit flex-row items-center justify-between gap-1 rounded-[4px] bg-primary-10 px-1.5 py-1 text-primary-100"
    >
      <UiIcon name="operations-forward" size="xxs" />
      <h5 class="text-caption">
        {{ stagedMoved }}
      </h5>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()
type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const authorName = props.modelValue.user
  ? props.modelValue.user.id === authStore.getUser.id
    ? 'You'
    : props.modelValue.user.name
  : ''
const stagedMoved = props.modelValue.additional?.find((i) => i.field === 'Pipeline stage')?.value
const pipeline = props.modelValue.additional?.find((i) => i.field === 'Pipeline')?.value
</script>

<style scoped></style>
