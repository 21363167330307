<template>
  <ol class="relative">
    <transition-group name="slideY">
      <UiTimelineItem
        v-for="item in modelValue"
        :key="item.id"
        :model-value="item"
        :module
        :can-view-highlighted-timeline
        :pin-view
        :disable-pin
        :show-pin
        class="mb-1 sm:mb-3"
        @update:model-value="handleUpdate"
        @update:pin="emits('update:pin', $event)"
      />
    </transition-group>
  </ol>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { PERMISSIONS } from '~/constants'

const emits = defineEmits(['update:modelValue', 'update:pin', 'update:timelineItem'])

type Props = {
  modelValue: TimelineItem[]
  module?: 'lead' | 'deal'
  pinView?: boolean
  disablePin?: boolean
  showPin?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  module: 'lead',
})

const canViewHighlightedTimeline = computed(() =>
  useHasPermissions([PERMISSIONS.VIEW_HIGHLIGHTED_NOTE_IN_TIMELINE], true)
)

const handleUpdate = (item: TimelineItem) => {
  emits('update:modelValue', [...props.modelValue, item])
  emits('update:timelineItem', item)
}
</script>
