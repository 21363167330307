<template>
  <div v-if="isUnitIsHeld" class="text-body-2 flex flex-col gap-2">
    <div>
      <span class="text-caption mr-2 rounded-lg bg-additional-1-50 p-1 text-error-100">{{ $t('on_hold') }}</span>
      <span class="text-body-2">
        <NuxtLink
          v-if="unitNumber"
          :to="`/properties/exclusives?query=${unitNumber}&project_ids=${projectId}`"
          target="_blank"
          class="text-subhead-4 underline"
        >
          {{ unitNumber }}
        </NuxtLink>
        <span v-else class="text-subhead-4 inline-block capitalize">{{ $t('unit') }}</span>

        has been reserved by
        <span class="text-subhead-4"> {{ reservedBy }} </span>.
      </span>
    </div>
    <div class="flex flex-col gap-1">
      <template v-if="isOtherPaymentMethod">
        <span class="text-caption text-black-40">Payment method:</span>
        <p class="text-body-2">
          <span
            class="text-subhead-4"
            :class="{ ' cursor-pointer text-primary-100': showLink }"
            @click="showConfirmationPopup"
          >
            Add payment confirmation</span
          >
          documents on a Tokens tab
        </p>
      </template>
      <template v-else>
        <span class="text-caption text-black-40">Payment link:</span>
        <p class="text-body-2">The payment link and token information are stored on the Tokens tab.</p>
      </template>
    </div>
  </div>
  <div v-else-if="isPaymentConfirmed || isVerificationPending">
    Token <span class="text-subhead-4">payment confirmation was added</span> by {{ actorName }}. Review documents on a
    Tokens tab.
  </div>
  <div v-else-if="isUnitIsCanceled">
    <span class="text-caption mr-2 rounded-lg bg-additional-3-10 p-1 text-additional-3-100">{{ $t('canceled') }}</span>
    <span
      >The payment token was canceled by <span class="text-subhead-4"> {{ actorName }} </span>.
      <NuxtLink
        v-if="unitNumber"
        :to="`/properties/exclusives?query=${unitNumber}&project_ids=${projectId}`"
        target="_blank"
        class="text-subhead-4 underline"
      >
        Unit {{ unitNumber }}
      </NuxtLink>
      has been removed from Hold and is now available for booking.
    </span>
  </div>
  <div v-else-if="isPaid">
    <span class="text-caption mr-2 rounded-lg bg-dm-10 p-1 text-black-90">{{ $t('booked') }}</span>
    <span
      >The token payment
      <span v-if="tokenAmount"> in the amount of {{ useMoneyFormatter(tokenAmount, { notation: 'standard' }) }}</span>
      for
    </span>
    <span>
      <NuxtLink
        v-if="unitNumber"
        :to="`/properties/exclusives?query=${unitNumber}&project_ids=${projectId}`"
        target="_blank"
        class="text-subhead-4 underline"
      >
        {{ unitNumber }}
      </NuxtLink>
    </span>
    was auto confirmed. The unit is now Booked.
  </div>
  <div v-else-if="isExpired">
    The token payment for
    <NuxtLink
      v-if="unitNumber"
      :to="`/properties/exclusives?query=${unitNumber}&project_ids=${projectId}`"
      target="_blank"
      class="text-subhead-4 underline"
    >
      {{ unitNumber }}
    </NuxtLink>
    was not confirmed <span v-if="reservationTime">within {{ reservationTime }} of reservation</span>. The unit is now
    available for all agents.
  </div>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import type { TimelineItem } from '@/types'
import { PAYMENT_SUBTYPES, PAYMENT_TOKEN_CODES } from '~/constants'
import type { UnitPaymentToken } from '~/types/properties'
import { useUiStore } from '~/store/ui'

const bus = useEventBus<UnitPaymentToken['id']>('showConfirmationPopup')

const isPaymentConfirmed = computed(() => props.modelValue.sub_type === PAYMENT_SUBTYPES.CONFIRMED)
const isUnitIsHeld = computed(() => props.modelValue.sub_type === PAYMENT_SUBTYPES.UNIT_IS_HELD)
const isUnitIsCanceled = computed(() => props.modelValue.sub_type === PAYMENT_SUBTYPES.UNIT_IS_CANCELLED)
const isPaid = computed(() => props.modelValue.sub_type === PAYMENT_SUBTYPES.PAID)
const isExpired = computed(() => props.modelValue.sub_type === PAYMENT_SUBTYPES.EXPIRED)
const isVerificationPending = computed(() => props.modelValue.sub_type === PAYMENT_SUBTYPES.VERIFICATION_PENDING)

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const uiStore = useUiStore()

const reservedBy = computed(() => props.modelValue.additional?.find((i) => i.field === 'Agent')?.value)

const unitNumber = computed(() => props.modelValue.additional?.find((i) => i.field === 'Unit number')?.value)
const projectId = computed(() => props.modelValue.additional?.find((i) => i.field === 'Project id')?.value)
const actorName = computed(() => props.modelValue.additional?.find((i) => i.field === 'Actor name')?.value)
const showLink = computed(() => uiStore.getActivePaymentTokens.includes(props.modelValue.payment_token_id!))
const tokenAmount = computed(() => Number(props.modelValue.additional?.find((i) => i.field === 'Token amount')?.value))
const reservationTime = computed(() => props.modelValue.additional?.find((i) => i.field === 'Reservation time')?.value)

const isOtherPaymentMethod = computed(
  () => props.modelValue.additional?.find((i) => i.field === 'Token type')?.value === PAYMENT_TOKEN_CODES.MANUAL
)

const showConfirmationPopup = () => {
  if (showLink.value) {
    bus.emit(props.modelValue.payment_token_id)
  }
}
</script>

<style scoped lang="scss"></style>
