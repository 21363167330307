<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
      <DynamicSidePanel />
      <DynamicMobilePanel />
      <DynamicPopup />
      <DynamicActionBar />
      <DynamicActionMenu />
      <template v-if="authStore.getIsLoggedIn">
        <LeadCallInbound />
        <SupportPanel v-if="!isExcluded" />
        <DynamicTour />
      </template>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import * as Sentry from '@sentry/vue'
import { useIOStore } from '~/store/io'
import { useAuthStore } from '~/store/auth'
import { useWhatsappStore } from '~/store/whatsapp'
import type { User } from '@/types'

const ioStore = useIOStore()
const authStore = useAuthStore()
const whatsappStore = useWhatsappStore()

onNuxtReady(async () => {
  if (authStore.getIsLoggedIn) await initUser()
})

watch(
  () => authStore.getUser,
  (value: User) => {
    if (
      value &&
      whatsappStore.getHasIntegration &&
      `${value.phones[0].code}${value.phones[0].phone}` !== whatsappStore.getWhatsappCurrentNumber
    ) {
      triggerWALogout()
    }
  }
)

watch(
  () => authStore.getIsLoggedIn,
  async (value: boolean) => {
    if (value) {
      await initUser()
      authStore.checkUserWANotifications()
    }
  }
)

const initUser = async () => {
  await Promise.all([useVersionCheck(), useGetProfile(), useGetUnreadNotificationsCount(), useGetOverdueTasks()])
  Sentry.setUser({
    id: authStore.getUser.id!,
    username: authStore.getUser.name,
    email: authStore.getUser.email,
    role: authStore.getUser.role?.name,
  })
  ioStore.activateCall()
}

const EXCLUDED_ROUTES_NAME = ['property-hash', 'properties-all']

const route = useRoute()

const routeName = computed(() => {
  return route.name
})

const isExcluded = computed(() => {
  return EXCLUDED_ROUTES_NAME.includes(routeName.value?.toString() || '')
})

onMounted(() => {
  // Work around for https://github.com/nuxt/nuxt/issues/13350
  // @TODO Remove when fixed
  if (typeof window !== 'undefined') {
    window.addEventListener('error', (ev) => {
      const messages = [
        `Uncaught NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`, // Chromium
        `NotFoundError: Node.insertBefore: Child to insert before is not a child of this node`, // Firefox
        `NotFoundError: The object can not be found here.`, // Safari
      ]
      if (messages.some((message) => ev.message.includes(message))) {
        ev.preventDefault()
        window.location.reload()
      }
    })
  }
})
</script>
