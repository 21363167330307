<template>
  <div class="flex flex-row items-center gap-1">
    <span class="text-subhead-3">New lead</span>
    {{ name }}
    <span class="text-subhead-3">was created</span>
    <span v-if="isToday(new Date(modelValue.created_at))">Today |</span>
    {{ format(new Date(modelValue.created_at), `d MMMM yyyy, HH:mm`) }}.
  </div>
</template>

<script setup lang="ts">
import { format, isToday } from 'date-fns'
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const name = props.modelValue.additional[0].value.split('Lead')[1]?.split('was')?.[0]
</script>
