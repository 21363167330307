<template>
  <div class="h-full">
    <div class="rounded-xl bg-white pt-3 xs:rounded-3xl xs:pt-6">
      <div class="mb-2 px-3 xs:px-6" :class="{ 'xs:mb-5': !modelValue?.deals?.length }">
        <h4 class="max-w-[360px] truncate [overflow-wrap:anywhere]">
          {{ modelValue!.name }}
        </h4>
      </div>
      <div v-if="modelValue?.deals?.length" class="mb-4 px-3 xs:px-6">
        <LeadInfoDealLink :deals="modelValue?.deals" />
      </div>
      <div v-if="!isMobile" class="flex flex-col gap-4 px-6 pb-6">
        <div class="flex flex-row items-center">
          <small class="w-1/3 text-black-60"> Lead stage </small>
          <small class="w-2/3 text-black-100">
            <LeadInfoStage
              v-model="modelValue!.pipeline_stage_id"
              :lead="modelValue!"
              :readonly="readonly || limitedAccess"
              class="px-0"
              @update:model-value="emits('update:modelValue', modelValue)"
            />
          </small>
        </div>
        <div class="flex h-10 flex-row items-center">
          <small class="w-1/3 text-black-60"> Tags </small>
          <div class="text-body-2 w-2/3 text-black-100">
            <div
              v-if="(filteredTags?.length || useGetStepTagPropertiesByCode(modelValue!.step?.code!)) && !isTagSelectionToggled"
              class="-ml-2 flex flex-row flex-wrap items-center gap-2 rounded-xl transition-colors duration-200"
              :class="{
                'cursor-pointer px-2 py-1.5 hover:bg-black-05': canEditTags,
              }"
              @click="handleToggleTagSelection"
            >
              <PipelineLeadTag
                v-if="useGetStepTagPropertiesByCode(modelValue!.step?.code!)"
                :id="modelValue!.id"
                :properties="useGetStepTagPropertiesByCode(modelValue!.step?.code!)!"
                :tag-code="modelValue!.step?.code!"
              />
              <PipelineLeadTag
                v-for="tag in filteredTags"
                :id="modelValue!.id"
                :key="tag.id"
                :properties="useGetTagPropertiesByCode(tag.code)!"
                :tag-code="tag.code"
              />
            </div>
            <template v-else>
              <UiInputSelect
                v-if="isTagSelectionToggled"
                id="tagSelect"
                ref="tagSelect"
                :model-value="filteredTags.map(({ id }) => id)"
                placeholder="Select a tag"
                name="tagSelect"
                :close-on-select="false"
                :removable="false"
                multiple
                :loading="isTagsItemsLoading"
                hide-multiple-search
                hide-arrow
                :items="tagsItems"
                @update:model-value="handleTagSelect"
                @click-outside="handleClickTagSelectOutside"
              >
                <template v-if="filteredTags.length || useGetStepTagPropertiesByCode(modelValue!.step?.code!)">
                  <div class="flex flex-row items-center gap-1">
                    <PipelineLeadTag
                      v-if="useGetStepTagPropertiesByCode(modelValue!.step?.code!)"
                      :id="modelValue!.id"
                      :properties="useGetStepTagPropertiesByCode(modelValue!.step?.code!)!"
                      :tag-code="modelValue!.step?.code!"
                      disabled
                      class="max-xl:[&_span]:!hidden"
                    />
                    <PipelineLeadTag
                      v-for="tag in filteredTags"
                      :id="tag.id"
                      :key="tag.id"
                      :properties="useGetTagPropertiesByCode(tag.code)!"
                      :tag-code="tag.code"
                      disabled
                      :removable="!!tagsItems.find(({ id }) => id === tag.id)"
                      class="max-xl:[&_span]:!hidden"
                      @remove="showRemoveTagPopup(tag)"
                    />
                  </div>
                </template>
              </UiInputSelect>
              <div
                v-else
                id="tagSelectToggler"
                class="-ml-4 flex h-10 items-center rounded-xl transition-colors duration-200"
                :class="{
                  'cursor-pointer px-4 py-3 hover:bg-black-05': canEditTags,
                }"
                @click="handleToggleTagSelection"
              >
                {{ canEditTags ? $t('select_tags') : $t('none') }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <template v-else>
        <div id="tags-and-stage">
          <div
            v-if="filteredTags?.length || useGetStepTagPropertiesByCode(modelValue!.step?.code!)"
            class="mb-3 flex flex-row flex-wrap items-center gap-2 px-3"
          >
            <PipelineLeadTag
              v-if="useGetStepTagPropertiesByCode(modelValue!.step?.code!)"
              :id="modelValue!.id"
              :properties="useGetStepTagPropertiesByCode(modelValue!.step?.code!)!"
              :tag-code="modelValue!.step?.code!"
              show-text
            />
            <PipelineLeadTag
              v-for="tag in filteredTags"
              :id="modelValue!.id"
              :key="tag.id"
              :properties="useGetTagPropertiesByCode(tag.code)!"
              :tag-code="tag.code"
              show-text
            />
          </div>
          <LeadInfoStage
            v-model="modelValue!.pipeline_stage_id"
            :lead="modelValue!"
            :readonly="readonly"
            class="mb-4 px-3"
            is-mobile
            @update:model-value="emits('update:modelValue', modelValue)"
          />
          <hr v-if="!isClosedLost" class="h-[1.5px] text-black-10" />
        </div>
        <LeadActions
          v-if="modelValue && !readonly"
          v-model="modelValue"
          class="relative z-10 p-4"
          is-mobile
          @reopen="emits('reopen')"
        />
      </template>
    </div>
    <div v-if="!isMobile" class="mb-2 mt-4 flex flex-row items-center justify-between">
      <UiTabsPrimary v-model="tab" :tabs="tabs" class="bg-white" small />
    </div>
    <transition v-if="!isMobile" name="fade" mode="out-in">
      <keep-alive>
        <component :is="currentComponent" v-model="modelValue" :readonly="readonly" />
      </keep-alive>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { POPUPS } from '~/components/dynamic/maps'
import type { Tab, Lead, Tag } from '@/types'
import { useAuthStore } from '@/store/auth'
import { TAGS, PIPELINES, PERMISSIONS, CUSTOM_EVENTS } from '@/constants'
import { useUiStore } from '~/store/ui'
import type { UiInputSelect } from '#build/components'

const route = useRoute()
const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'reopen'])

const modelValue = defineModel<Lead>()

type Props = {
  readonly?: boolean
}

defineProps<Props>()

const { smaller } = useBreakpoints(useGetBreakpoints())

const isMobile = smaller('xs')

const isClosedLost = inject<Ref<boolean>>('isClosedLost')

const goStage = useCookie('goStage')
const tab = ref('details')
const tabs: Tab[] = [
  {
    id: 'details',
    text: 'Details',
  },
  {
    id: 'source',
    text: 'Source',
  },
]

const tabComponentMap = {
  details: resolveComponent('LeadInfoDetails'),
  source: resolveComponent('LeadInfoSource'),
}

const currentComponent = computed(() => tabComponentMap[tab.value as keyof typeof tabComponentMap])

const authStore = useAuthStore()

const isTagSelectionToggled = ref(false)
const canEditTags = computed(() => useHasPermissions([PERMISSIONS.CAN_EDIT_LEAD_TAGS]))
const tagSelect = ref<InstanceType<typeof UiInputSelect> | null>(null)
const isTagsItemsLoading = ref(false)
const tagsItems = ref<Tag[]>([])

const handleToggleTagSelection = () => {
  if (!canEditTags.value) return

  isTagSelectionToggled.value = !isTagSelectionToggled.value

  if (isTagSelectionToggled.value) {
    nextTick(() => {
      if (tagSelect.value) {
        tagSelect.value.setInputFocused(true)
      }
    })
  }
}

const handleTagSelect = (tagIds: Tag['id'][]) => {
  const excludedTags = modelValue.value!.tags.filter(({ id }) => !tagsItems.value.find(({ id: tagId }) => tagId === id))
  const tags = tagsItems.value.filter(({ id }) => tagIds.includes(id))
  const newTags = [...excludedTags, ...tags]
  const tagsWasAdded = newTags.length > modelValue.value!.tags.length
  if (tagsWasAdded) {
    modelValue.value!.tags = newTags
    updateTags(newTags)
  } else {
    const removedTag = modelValue.value!.tags.find(({ id }) => !tagIds.includes(id))
    showRemoveTagPopup(removedTag!)
  }
}

const handleClickTagSelectOutside = () => {
  isTagSelectionToggled.value = false
}

const showRemoveTagPopup = (tag: Tag) => {
  isTagSelectionToggled.value = false
  uiStore.showPopup(POPUPS.PIPELINE_LEAD_REMOVE_TAG, { tag: tag.name }, { input: () => handleTagRemove(tag.id) })
}

const handleTagRemove = (id: Tag['id']) => {
  modelValue.value!.tags = modelValue.value!.tags.filter((tag: Tag) => tag.id !== id)
  updateTags(modelValue.value!.tags)
}

const updateTags = async (tags: Tag[]) => {
  try {
    const data = await useUpdateTagsLead(modelValue.value!.id, { tag_ids: tags.map(({ id }) => id) })
    uiStore.showSnackBanner(data.message)
    refreshLead()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const refreshLead = () => {
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))
}

const filteredTags = computed(() => {
  return modelValue.value!.tags?.filter(({ code }) => {
    if (authStore.getIsAgent && (code === TAGS.SHADOW_ORIGINAL_LEAD || code === TAGS.SHADOW_COPY_LEAD)) {
      return false
    }

    return true
  })
})

const limitedAccess = computed(() => authStore.getIsSdr && modelValue.value?.pipeline?.code === PIPELINES.QUEUE)

watch(
  () => goStage.value,
  (value) => {
    if (value && tab.value !== 'details') tab.value = 'details'
  }
)

onNuxtReady(() => {
  getTags()
})

const getTags = async () => {
  try {
    isTagsItemsLoading.value = true
    const tags = await useGetTags()
    tagsItems.value = tags
      .filter((tag: Tag) => tag.code === TAGS.FBYB || tag.code === TAGS.DIC)
      .map((tag: Tag) => ({ ...tag, value: tag.id, text: tag.name }))
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    isTagsItemsLoading.value = false
  }
}
</script>

<style scoped></style>
